#seijin {
  background: $color-white;

  .seijin-header {
    background-image: url(../img/seijin/header_sp.jpg);
    background-size: cover;
    background-position: center center;
    height: 300px;

    @include media-up(md){
      background-image: url(../img/seijin/header_pc.jpg);
      height: 600px;
    }
  }

  .seijin-read {
    background-color: #F7F7F7;
    padding: 30px 0;

    @include media-up(md){
      padding: 60px 0;
    }

    h2 {
      text-align: center;
      font-size: 16px;
      font-weight: bold;

      @include media-up(md){
        font-size: 24px;
      }
    }

    p {
      font-size: 12px;
      margin-top: 20px;

      @include media-up(md){
        font-size: 16px;
        text-align: center;
        line-height: 2;
      }

      br {
        display: none;

        @include media-up(md){
          display: inherit;
        }
      }
    }
  }

  .seijin-contents {
    margin-top: 45px;

    @include media-up(md){
      margin-top: 90px;
    }

    h2 {
      font-size: 18px;
      font-weight: bold;
      text-align: center;

      @include media-up(md){
        font-size: 24px;
      }

      br {
        @include media-up(md){
          display: none;
        }
      }
    }

  }

  .seijin-contents__item {
    margin-top: 40px;
    padding-bottom: 40px;

    @include media-up(md){
      margin-top: 60px;
      padding-bottom: 100px;
    }
    dl {
      display: flex;
      flex-direction: column;

      @include media-up(md){
        flex-direction: row;
        justify-content: space-between;
      }

      dt {
        @include media-up(md){
          width: 340px;
        }
        img {
          width: 100%;
          height: auto;
          vertical-align: middle;
        }
      }

      dd {
        margin-top: 20px;

        @include media-up(md){
          margin-top: 20px;
          width: calc(100% - 380px);

        }

        h3{
          font-size: 15px;
          font-weight: bold;

          @include media-up(md){
            font-size: 20px;
          }

          br {
            display: none;

            @include media-up(md){
              display: inherit;
            }
          }
        }

        p {
          margin-top: 10px;
          font-size: 12px;

          @include media-up(md){
            font-size: 14px;
            line-height: 2;
          }
        }
      }
    }
    + .nail-contents__item {
      margin-top: 40px;

      @include media-up(md){

        dl {
          flex-direction: row-reverse;
        }
      }
    }
  }

  .seijin-schedule {
    background-color: #FD6A80;
    padding: 40px 0;

    @include media-up(md){
      padding: 80px 0;
    }
  }

  .seijin-schedule__inner {
  }

  .seijin-schedule__title {
    h2 {
      font-size: 18px;
      font-weight: bold;
      color: $color-white;
      text-align: center;
      @include media-up(md){
        font-size: 24px;
      }
    }
  }

  .seijin-schedule__banner {
    position: relative;
    margin-top: 20px;
    .show-pc {
      display: none;
      @include media-up(md){
        display: inherit;
      }
    }
    .show-sp {
      @include media-up(md){
        display: none;
      }
    }

    .seijin-schedule-badge {
      position: absolute;
      top: 0;
      right: 60px;
      transform: scale(1);
      transition: all .2s ease;
      opacity: 1;

      &:hover {
        transform: scale(0.9);
        opacity: 0.9;
      }
    }

    .seijin-schedule-banner {
      background-color: #A98E79;
      display: block;
      border-radius: 10px;
      padding: 20px 10px;
      color: $color-white;
      text-decoration: none;
      border: 3px solid #fff;
      max-width: 400px;
      margin: 0 auto;

      dl {
        display: flex;
        align-items: center;
        justify-content: center;

        dt {
          padding-right: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
          img {
            vertical-align: middle;
          }
        }

        dd {
          p {
            font-size: 18px;
          }

          span {
            font-size: 8px;
            display: block;
            margin-top: 0px;
          }
        }
      }
    }
  }

  .seijin-schedule__figure {
    background-color: #fff;
    padding: 10px;
    margin-top: 20px;
    display: none;

    @include media-up(md){
      padding: 40px;
      border-radius: 10px;
      max-width: 800px;
      width: 100%;
      margin: 40px auto 0;
      display: inherit;
    }

    img {
      width: 100%;
    }
  }

  .seijin-bring {
    padding-top: 40px;
    padding-bottom: 40px;

    @include media-up(md){
      padding-top: 80px;
      padding-bottom: 80px;
    }
  }

  .seijin-bring__title {
    h2 {
      font-size: 18px;
      font-weight: bold;
      text-align: center;
      @include media-up(md){
        font-size: 24px;
      }
    }
  }

  .seijin-bring__contents {
    margin-top: 20px;
    display: flex;
    flex-direction: column;

    @include media-up(md){
      margin-top: 40px;
      flex-direction: row;
      justify-content: space-between;
    }

    .seijin-bring__item {

      @include media-up(md){
        width: 48%;
      }

      + .seijin-bring__item {
        margin-top: 30px;

        @include media-up(md){
          margin-top: 0;
        }
      }

      h3 {
        background-color: #A98E79;
        color: $color-white;
        font-size: 15px;
        text-align: center;
        padding: 10px;


        @include media-up(md){
          br {
            display: none;
          }
        }
      }
      p {
        margin-top: 20px;

        + h3 {
          margin-top: 30px;
        }
      }
    }
  }

  .seijin-bring__info {
    margin-top: 30px;
    border: 1px solid #000;
    padding: 20px;

    @include media-up(md){
      margin-left: 100px;
      margin-right: 100px;
      margin-top: 50px;
      padding: 25px 100px;
    }

    h3 {
      font-size: 18px;
      font-weight: bold;
      text-align: center;

      @include media-up(md){
        font-size: 20px;
      }
    }

    p {
      margin-top: 10px;
      font-size: 12px;

      @include media-up(md){
        margin-top: 20px;
      }
    }
  }

  .seijin-discount {
    background-color: $color-gray;
    padding-top: 40px;
    padding-bottom: 40px;

    @include media-up(md){
      padding-top: 80px;
      padding-bottom: 80px;
    }
  }

  .seijin-discount__title {
    h2 {
      font-size: 18px;
      font-weight: bold;
      text-align: center;
      @include media-up(md){
        font-size: 24px;
      }
    }
  }

  .seijin-discount__list {
    margin-top: 40px;

    @include media-up(md){
      margin-top: 60px;
    }

    ul {
      display: flex;
      flex-direction: column;

      @include media-up(md){
        flex-direction: row;
      }

      li {
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        max-width: 300px;

        + li {
          margin-top: 40px;
          @include media-up(md){
            margin-top: 0;
          }
        }

        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }

  .seijin-discount__caption {
    margin-top: 40px;
    font-size: 12px;
    padding-right: 5%;
    padding-left: 5%;

    @include media-up(md){
      margin-top: 60px;
      padding-left: 100px;
      padding-right: 100px;
    }
  }

  .seijin-price {
    background-color: #FD6A80;
    padding-top: 40px;
    padding-bottom: 0px;

    @include media-up(md){
      padding-top: 80px;
      padding-bottom: 0px;
    }
  }

  .seijin-price__title {
    text-align: center;
    font-size: 20px;
    font-family: $font-en;
    font-weight: bold;

    @include media-up(md){
      font-size: 24px;
    }
  }

  .seijin-price-contents {
    margin-top: 30px;
    padding-bottom: 40px;

    @include media-up(md){
      margin-top: 40px;
      padding-bottom: 80px;
      padding-left: 50px;
      padding-right: 50px;
    }


    .seijin-price-contents__caption {
      text-align: right;
      font-size: 10px;

      @include media-up(md){
        font-size: 12px;
      }
    }

    .seijin-price-contents__list {
      background-color: $color-white;
      margin-top: 5px;
      padding: 20px;

      @include media-up(md){
        padding: 50px;
      }

      .seijin-price-contents__item {
        padding: 10px 14px;
        display: flex;
        flex-direction: column;
        font-size: 12px;

        @include media-up(md){
          padding: 20px;
          font-size: 16px;
          flex-direction: row;
          border-top: 1px dotted#B4B4B4;
        }

        + .seijin-price-contents__item {
          margin-top: 40px;
        }


        .title {
          @include media-up(md){
            width: 40%;
          }

          h3 {
            font-size: 18px;
            text-align: center;
            font-weight: bold;

            @include media-up(md){
              font-size: 16px;
              text-align: left;
            }
          }

          p {
            font-size: 10px;
            margin-top: 10px;

            @include media-up(md){
              font-size: 12px;
              padding-right: 50px;
            }
          }
        }

        .price {
          margin-top: 20px;

          @include media-up(md){
            margin-top: 0;
            width: 60%;
          }

          dl {
            display: flex;
            align-items: center;
            padding-bottom: 15px;
            border-bottom: 1px dotted#B4B4B4;

            + dl {
              margin-top: 15px;
            }

            dt {
              width: 60%;

              @include media-up(md){
                width: 80%;
                font-size: 12px;
              }

              strong {
                font-size: 14px;
                font-weight: bold;
                display: block;
                padding-bottom: 5px;

                @include media-up(md){
                  font-size: 16px;
                }
              }
            }

            dd {
              width: 40%;
              padding-left: 20px;
              font-size: 14px;

              @include media-up(md){
                width: 20%;
                font-size: 16px;
              }
            }
          }
        }
      }

    }

    .nail-price-contents__text {
      margin-top: 5px;
      font-size: 10px;

      @include media-up(md){
        margin-top: 20px;
        padding: 0 10px;
        font-size: 12px;
      }
    }

  }


}

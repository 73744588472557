#contact {
  .contact-header {
    text-align: center;
    padding-top: 40px;

    @include media-up(md){
      padding-top: 0;
    }

    .contact-header__title {
      h1 {
        font-size: 20px;
        font-weight: bold;
        font-family: $font-en;

        @include media-up(md){
          font-size: 30px;
        }
      }
    }
    .contact-header__text {
      margin-top: 30px;
      font-size: 12px;
      text-align: left;

      br {
        display: none;

        @include media-up(md){
          display: block;
        }
      }

      @include media-up(md){
        padding-right: 100px;
        padding-left: 100px;
        font-size: 14px;
        text-align: center;
        margin-top: 50px;
      }

      @include media-up(lg){
        padding-right: 150px;
        padding-left: 150px;
        font-size: 14px;
        text-align: center;
      }

      &.contact-thanks {
        padding-bottom: 80px;

        h2 {
          margin-bottom: 40px;
          font-size: 18px;
          text-decoration: underline;

          @include media-up(md){
            font-size: 22px;
          }
        }

        p {
          text-align: left;

          + p {
            margin-top: 30px;
          }

          > a {
            margin-top: 40px;
          }
        }

      }
    }
  }

  .contact-contents {
    margin-top: 30px;
    padding-bottom: 40px;

    @include media-up(md){
      margin-top: 50px;
      padding-bottom: 80px;
      padding-left: 50px;
      padding-right: 50px;
    }

    .contact-tell {
      background-color: $color-white;
      margin-top: 5px;

      @include media-up(md){
        padding: 50px;
      }

      .contact-tell__title {
        text-align: center;
        font-size: 18px;
        font-weight: bold;
        padding-top: 30px;

        @include media-up(md){
          font-size: 20px;
        }
      }

      .contact-tell__head {
        display: none;

        @include media-up(md){
          display: inherit;
          font-size: 10px;
        }
      }

      .contact-tell__list {
        padding: 20px;

        @include media-up(md){
          padding: 40px 20px;
          margin-top: 30px;
        }
        > div {
          @include media-up(md){
            display: flex;
            align-items: center;
            .name {
              width: 15%;
            }
            .tel {
              width: 23%;
            }
            .adr {
              width: 27%;
            }
            .open {
              width: 25%;
            }
            .close {
              width: 15%;
            }
          }
        }
      }
      .contact-tell__item {
        margin-top: 10px;
        border-top: 1px dotted #000;
        padding: 10px;

        &:last-child {
          border-bottom: 1px dotted #000;
        }

        .name {
          font-size: 18px;
          font-weight: bold;
          text-align: center;
          padding: 10px 0 5px;

          @include media-up(md){
            text-align: left;
            padding: 0;
            font-size: 16px;
            font-weight: normal;
          }
        }

        .tel {
          font-size: 20px;
          font-weight: 500;
          text-align: center;

          @include media-up(md){
            text-align: left;
            padding: 0;
            font-size: 18px;
          }

          a {
            font-size: 14px;
            color: $color-black;

            @include media-up(md){
              font-size: 12px;
            }
          }
        }

        .adr {
          text-align: center;
          font-size: 14px;
          margin-top: 5px;

          @include media-up(md){
            text-align: left;
            margin-top: 0;
            font-size: 12px;
          }

          br {
            display: none;
            @include media-up(md){
              display: block;
            }
          }
        }
        .open {
          text-align: center;
          font-size: 12px;
          margin-top: 5px;

          @include media-up(md){
            text-align: left;
            margin-top: 0;
            font-size: 10px;
          }
        }

        .close {
          font-size: 12px;
          text-align: center;
          margin-top: 5px;

          @include media-up(md){
            text-align: left;
            margin-top: 0;
            font-size: 10px;
          }

          span {
            @include media-up(md){
              display: none;
            }
          }
        }
      }
    }

    .contact-form {
      margin-top: 50px;

      @include media-up(md){
        margin-top: 100px;
      }

      .contact-form__title {
        text-align: center;
        font-size: 18px;
        font-weight: bold;

        @include media-up(md){
          font-size: 20px;
        }
      }

      .contact-form__text {
        margin-top: 20px;
        font-size: 10px;

        @include media-up(md){
          margin-top: 30px;
          text-align: center;
          line-height: 2;
          font-size: 13px;
        }
      }

      .contact-form__form {
        margin-top: 30px;
        border-top: 1px dotted #000;

        .contact-form__item {
          border-bottom: 1px dotted #000;
          padding: 20px 10px;
          display: flex;
          flex-direction: column;

          @include media-up(md){
            flex-direction: row;
            align-items: center;
            padding: 30px 20px;
          }

          + .contact-form__item {
          }

          dt {
            font-size: 14px;

            @include media-up(md){
              font-size: 14px;
              width: 200px;
            }

            br {
              display: none;
              @include media-up(md){
                display: inherit;
              }
            }

            span {
              color: red;
              margin-left: 2px;
            }

            p {
              font-size: 10px;
            }
          }

          dd {
            margin-top: 10px;

            @include media-up(md){
              font-size: 16px;
              width: calc(100% - 200px);
              margin-top: 0;
            }

            input[type=text] {
              background-color: $color-white;
              width: 100% !important;
              padding: 10px;
              font-size: 16px;

              @include media-up(md){
                max-width: 400px !important;
              }
            }

            input[type=radio] {
            }

            label {
              font-size: 14px;
              + label {
                margin-left: 10px;
              }
            }

            p {
              font-size: 10px;
              margin-top: 5px;
            }

            textarea {
              background-color: $color-white;
              width: 100% !important;
              padding: 10px;
              font-size: 16px;
              min-height: 300px;
            }



            .adr {
              .adr1 {
                display: flex;
                align-items: center;
                span {
                  font-size: 20px;
                  font-weight: bold;
                  padding-right: 10px;
                }
                input {
                  max-width: 150px;
                }
              }

              .adr2 {
                margin-top: 10px;
              }
            }

            &.checkbox-list {
              display: flex;
              flex-direction: column;


              label {
                margin-left: 0;

                + label {
                  margin-top: 5px;
                }
              }
            }
          }
        }
      }

      input[type=button] {
        width: 100% !important;
        padding: 20px;
      }

    }

    .contact-submit {
      margin-top: 30px;
      max-width: 300px;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      text-align: center;

      @include media-up(md){
        max-width: 330px;
        margin-top: 60px;
      }

      a {
        font-size: 20px;
        padding: 15px;

        @include media-up(md){
          font-size: 22px;
          padding: 20px 0;
        }
      }
    }


  }
}

#global-header {
  background-color: $color-white;
  position: fixed;
  top:0;
  left:0;
  width: 100%;
  z-index: 9999;

  &.is-fixed {
    @include media-up(md){
      top: 40px;
    }
  }

  .global-header__inner {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 40px;
    padding-top: 5px;

    @include media-up(md){
      flex-direction: row;
      justify-content: flex-start;
      height: 50px;
      padding-top: 0;

    }
  }

  .global-header__logo {
    @include media-up(md){
      margin-right: 50px;
    }
    @include media-up(lg){
      margin-right: 85px;
    }
    img {
      vertical-align: middle;
    }

    a {
      vertical-align: top;
    }

  }

  .global-header__nav {
    display: block;
    position: absolute;
    top: 35px;
    background-color: #fff;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;

    @include media-up(md){
      top:45px;
      display: block;
      position: relative;
      top:auto;
      width: auto;
      background-color: rgba(#fff,0);
    }


    ul {
      display: flex;
      flex-wrap: wrap;
      max-width: 1060px;
      min-width: 380px;
      height: 40px;
      line-height: 1;
      margin: 0 auto;
      list-style: none;
      margin-top: 15px;
      margin-bottom: 5px;
      padding-left: 25px;
      padding-right: 25px;


      @include media-up(xxs){
        min-width: auto;
        max-width: auto;
        display: flex;
        justify-content: center;
      }

      @include media-up(md){
        display: flex;
        max-width: auto;
        min-width: auto;
        height: auto;
        margin: 0;
        padding-left: 0;
        padding-right: 0;
      }

      li {
        margin-right: 20px;

        @include media-up(xxs){
          margin: 0 10px;
        }

        @include media-up(md){
          margin-right: 15px;
          margin-left: 0;
        }
        @include media-up(lg){
          margin-right: 40px;
        }

        &.pc-show {
          display: none;

          @include media-up(md){
            display: inherit;
          }
        }
        a {
          text-decoration: none;
          color: $color-black;
          letter-spacing: 0.03em;
          font-size: 12px;
        }
      }
    }
  }
}


#fixed-topics {
  position: fixed;
  top: 95px;
  left:0;
  z-index: 9999;
  width: 100%;
  display: block;
  animation: show 0.2s forwards;

  @include media-up(md){
    top:0 !important;
    display: block !important;
  }


  &.is-hidden {
    display: none;
    transition: 0.5s;
  }

  @keyframes show {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
  }
}

  @include media-up(md){
    top: 50px;
  }

  a {
    width: 100%;
    background-color: red;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $color-white;
    text-decoration: none;
    font-size: 12px;
    line-height: 1;
    transition: all .1s ease;
    opacity: 1;

    &:hover {
      opacity: 0.95;

      span {
        text-decoration: none;
      }
    }

    &.black {
      background-color: $color-black;
    }

    &.blue {
      background-color: $color-main;
    }
  }

  span {
    padding-left: 20px;
    text-decoration: underline;
  }
}

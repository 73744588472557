//-----------------------------------------------------------------------------------
//
//  common.scss
//
//  1. font style
//  2. layout style
//
//-----------------------------------------------------------------------------------

//////////////////////////////////////////////////////////
// 1. font style
//////////////////////////////////////////////////////////

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  font-family: $font-body;
  font-size: 14px;
  line-height: 1.6;
  color: $color-black;
  text-align: left;
  letter-spacing: 0.05em;
  // background: $color-sub;


  &.is-fixed {
    overflow: hidden;
  }

  &.is-added {
    padding-top: 70px;

    @include media-up(lg){
      padding-top: 100px;
    }
  }
}

::selection {
    background: $color-black;
    color: $color-white;
    text-shadow: none;
}

#loading {
  background-color: $color-main;
  background-image: url(../img/common/texture.png);
  background-repeat: repeat;
  background-size: 216px auto;
  width: 100%;
  height: 100vh;
  position: fixed;
  left:0;
  top:0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  animation: load 2s 1;
  opacity: 0;
  visibility: hidden;

  .loading-image {
    width: 50%;
    animation: logo ease-out 1s 1;

    @include media-up(lg){
      width: 30%;
    }
    img {
      width: 100%;
    }
  }
}

@keyframes load {
	0% {
    opacity: 1;
    visibility: visible;
    display: flex;
  }

  60% {
    opacity: 1;
    visibility: visible;
  }


	99% {
    opacity: 0;
    visibility: hidden;
  }

  100% {
    display: none;
  }
}

@keyframes logo {
	0% {
    opacity: 0;
    margin-bottom: -40px;
  }
  100% {
    opacity: 1;
    margin-bottom: 0;
  }
}


.is-fadein {
	opacity : 0.1;
	transform : translate(0, 50px);
	transition : all 500ms;
	}

/* 画面内に入った状態 */
.is-fadein.scrollin {
	opacity : 1;
	transform : translate(0, 0);
	}


.is-nomargin {
  margin-top: 0 !important;
  padding-top: 0 !important;
}


.show-sp {
  display: inherit;

  @include media-up(md){
    display: none;
  }
}

.show-pc {
  display: none;

  @include media-up(md){
    display: inherit;
  }
}

#graduate {
  background: $color-white;

  .graduate-header {
    background-image: url(../img/graduate/header_sp.jpg);
    background-size: cover;
    background-position: center center;
    height: 300px;

    @include media-up(md) {
      background-image: url(../img/graduate/header_pc.jpg);
      height: 600px;
    }
  }

  .graduate-read {
    background-color: #F7F7F7;
    padding: 30px 0;

    @include media-up(md) {
      padding: 60px 0;
    }

    h2 {
      text-align: center;
      font-size: 16px;
      font-weight: bold;

      @include media-up(md) {
        font-size: 24px;
      }
    }

    p {
      font-size: 12px;

      @include media-up(md) {
        font-size: 16px;
        text-align: center;
        line-height: 2;
      }

      br {
        display: none;

        @include media-up(md) {
          display: inherit;
        }
      }
    }
  }

  .graduate-info {
    padding-top: 40px;
    padding-bottom: 40px;

    @include media-up(md) {
      padding-top: 80px;
      padding-bottom: 80px;
    }
  }

  .graduate-info__title {
    h2 {
      font-size: 18px;
      font-weight: bold;
      text-align: center;

      @include media-up(md) {
        font-size: 24px;
      }
    }
  }

  .graduate-info__contents {
    margin-top: 20px;
    display: flex;
    flex-direction: column;

    @include media-up(md) {
      margin-top: 0px;
      justify-content: space-between;
    }

    .graduate-info__schedule {
      text-align: center;

      h2 {
        font-size: 18px;
        font-weight: bold;
        text-align: center;
        margin-bottom: 20px;

        @include media-up(md) {
          font-size: 24px;
          margin-bottom: 30px;
        }
      }

      img {
        max-width: 100%;
      }

      .show-pc {
        display: none;

        @include media-up(md) {
          display: inherit;
        }
      }

      .show-sp {
        @include media-up(md) {
          display: none;
        }
      }

      .seijin-schedule__banner {
        position: relative;
        margin-top: 20px;


        .seijin-schedule-badge {
          position: absolute;
          top: -20px;
          right: 60px;
          transform: scale(1);
          transition: all .2s ease;
          opacity: 1;

          &:hover {
            transform: scale(0.9);
            opacity: 0.9;
          }
        }

        .seijin-schedule-banner {
          background-color: #A98E79;
          display: block;
          border-radius: 10px;
          padding: 20px 10px;
          color: $color-white;
          text-decoration: none;
          border: 3px solid #fff;
          max-width: 400px;
          margin: 0 auto;
          text-align: left;

          dl {
            display: flex;
            align-items: center;
            justify-content: center;

            dt {
              padding-right: 20px;
              display: flex;
              justify-content: center;
              align-items: center;
              height: 100%;

              img {
                vertical-align: middle;
              }
            }

            dd {
              p {
                font-size: 18px;
              }

              span {
                font-size: 8px;
                display: block;
                margin-top: 0px;
              }
            }
          }
        }
      }


    }

    .graduate-info__item {

      margin-top: 50px;

      @include media-up(md) {
        margin-top: 80px;
      }

      h2 {
        font-size: 18px;
        font-weight: bold;
        text-align: center;
        margin-bottom: 20px;

        @include media-up(md) {
          font-size: 24px;
          margin-bottom: 30px;
        }
      }

      h3 {
        background-color: #A98E79;
        color: $color-white;
        font-size: 15px;
        text-align: center;
        padding: 10px;


        @include media-up(md) {
          br {
            display: none;
          }
        }
      }

      p {
        margin-top: 20px;

        +h3 {
          margin-top: 30px;
        }
      }

      img {
        width: 100%;
        margin: 0 auto;
        display: block;
        height: auto;
      }

      .item-list {
        @include media-up(md) {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }

        .item-col {
          @include media-up(md) {
            width: 48%;
          }
        }
      }
    }
  }


  .graduate-price {
    background-color: #FDC649;
    padding-top: 40px;
    padding-bottom: 0px;

    @include media-up(md) {
      padding-top: 80px;
      padding-bottom: 0px;
    }
  }

  .graduate-price__title {
    text-align: center;
    font-size: 20px;
    font-family: $font-en;
    font-weight: bold;

    @include media-up(md) {
      font-size: 24px;
    }
  }

  .graduate-price-contents {
    margin-top: 30px;
    padding-bottom: 40px;

    @include media-up(md) {
      margin-top: 40px;
      padding-bottom: 80px;
      padding-left: 50px;
      padding-right: 50px;
    }


    .graduate-price-contents__caption {
      text-align: right;
      font-size: 10px;

      @include media-up(md) {
        font-size: 12px;
      }
    }

    .graduate-price-contents__list {
      background-color: $color-white;
      margin-top: 5px;
      padding: 20px;

      @include media-up(md) {
        padding: 50px;
      }

      .graduate-price-contents__item {
        padding: 10px 14px;
        display: flex;
        flex-direction: column;
        font-size: 12px;

        @include media-up(md) {
          padding: 20px;
          font-size: 16px;
          flex-direction: row;
          border-top: 1px dotted#B4B4B4;
        }

        +.graduate-price-contents__item {
          margin-top: 40px;
        }


        .title {
          @include media-up(md) {
            width: 40%;
          }

          h3 {
            font-size: 18px;
            text-align: center;
            font-weight: bold;

            @include media-up(md) {
              font-size: 16px;
              text-align: left;
            }


          }

          p {
            font-size: 10px;
            margin-top: 10px;

            @include media-up(md) {
              font-size: 12px;
              padding-right: 50px;
            }
          }
        }

        .price {
          margin-top: 20px;

          @include media-up(md) {
            margin-top: 0;
            width: 60%;
          }

          dl {
            display: flex;
            align-items: center;
            padding-bottom: 15px;
            border-bottom: 1px dotted#B4B4B4;

            +dl {
              margin-top: 15px;
            }

            dt {
              width: 60%;

              @include media-up(md) {
                width: 80%;
                font-size: 12px;
              }

              strong {
                font-size: 14px;
                font-weight: bold;
                display: block;
                padding-bottom: 5px;

                @include media-up(md) {
                  font-size: 16px;
                }
              }
            }

            dd {
              width: 40%;
              padding-left: 20px;
              font-size: 14px;

              @include media-up(md) {
                width: 20%;
                font-size: 16px;
              }
            }
          }
        }
      }

    }

    .nail-price-contents__text {
      margin-top: 5px;
      font-size: 10px;

      @include media-up(md) {
        margin-top: 20px;
        padding: 0 10px;
        font-size: 12px;
      }
    }

  }

  .graduate-coupon {
    background-color: #A98E79;
    padding: 40px 0;
    color: #fff;

    @include media-up(md){
      padding: 80px 0;
    }

    .graduate-coupon__title {
      h2 {
        font-size: 18px;
        font-weight: bold;
        text-align: center;

        @include media-up(md) {
          font-size: 24px;
        }
      }
    }

    .graduate-coupon__image {
      padding: 20px 0;
      text-align: center;
    }

    img {
      width: auto;
      max-width: 100%;
      height: auto;
      box-shadow: 2px 2px 4px rgba(#000, 0.2);

      @include media-up(md){
        width: 50%;
        min-width: 400px;
        height: auto;
      }
    }

    .graduate-coupon__text {
      margin-top: 10px;
      font-size: 16px;
      text-align: center;
      font-weight: bold;
    }
  }

}

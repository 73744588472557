#staff {
  .staff-header {
    text-align: center;
    padding-top: 40px;

    @include media-up(md){
      padding-top: 0;
    }

    .staff-header__title {
      h1 {
        font-size: 20px;
        font-weight: bold;
        font-family: $font-en;

        @include media-up(md){
          font-size: 30px;
        }
      }
    }

    .staff-header__nav {
      margin-top: 20px;

      @include media-up(md){
        margin-top: 40px;
      }

      ul {
        display: flex;
        justify-content: center;

        li {
          display: flex;
          align-items: center;

          + li {
            &::before {
              content: '';
              display: block;
              height: 100%;
              width: 1px;
              background-color: $color-black;
              font-weight: bold;
              margin: 0 10px;
              line-height: 1;

              @include media-up(md){
                margin: 0 20px;
              }
            }
          }
          a {
            color: $color-black;
            text-decoration: none;
            font-weight: bold;
            font-size: 13px;
            line-height: 1;
            font-family: $font-en;

            @include media-up(md){
              font-size: 16px;

              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }
    }
  }

  .staff-contents {
    margin-top: 30px;
    padding-bottom: 40px;

    @include media-up(md){
      margin-top: 60px;
      padding-bottom: 80px;
    }

    .staff-contents__list {

    }
    .staff-contents__section {
      + .staff-contents__section {
        margin-top: 40px;

        @include media-up(md){
          margin-top: 80px;
        }
      }

      h2 {
        font-size: 16px;
        font-family: $font-en;
        font-weight: bold;

        @include media-up(md){
          font-size: 20px;
        }
      }
      ul {
        margin-top: 15px;
        display: flex;
        flex-wrap: wrap;
        margin-left: -10px;
        margin-top: -5px;

        @include media-up(md){
          margin-left: -20px;
          margin-top: -10px;
        }

        li {
          width: calc(50% - 10px);
          margin-left: 10px;
          position: relative;
          margin-top: 10px;

          @include media-up(md){
            margin-left: 20px;
          }

          @include media-up(xs){
            width: calc(33.333% - 10px);
          }

          @include media-up(md){
            width: calc(25% - 20px);
            margin-left: 20px;
            margin-top: 20px;
          }

          @include media-up(lg){
            width: calc(20% - 20px);
          }

          .salon {
            position: absolute;
            background-color: #C7C7C7;
            padding: 5px 10px;
            right: 0;
            top:0;
            line-height: 1;
            vertical-align: middle;
            font-size: 10px;
            color: $color-white;
            font-family: $font-en;
            min-width: 50px;
            text-align: center;

            @include media-up(md){
              font-size: 12px;
            }
          }


          dl {
            background-color: $color-white;
            dt {
              img {
                width: 100%;
                height: auto;
                vertical-align: middle;
                image-rendering: pixelated;
              }
            }

            dd {
              padding: 5px;
              display: flex;

              @include media-up(md){
                padding: 15px;
              }

              .name {
                width: calc(100% - 35px);

                p {
                  font-size: 14px;
                  letter-spacing: 0.1em;

                  @include media-up(md){
                    font-size: 17px;
                  }

                  span {
                    display: block;
                    font-size: 10px;
                    font-weight: normal;
                    letter-spacing: 0.04em;

                    @include media-up(md){
                      font-size: 12px;
                    }
                  }
                }

                .title {
                  font-size: 8px;

                  @include media-up(md){
                    font-size: 10px;
                  }
                }
              }

              .sns {
                width: 35px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                a {
                  width: 35px;
                  height: 35px;
                  padding: 5px;

                  @include media-up(md){
                    opacity: 1;
                    transition: all .1s ease;

                    &:hover {
                      opacity: 0.5;
                    }
                  }

                  img {
                    width: 100%;
                    height: auto;
                    vertical-align: middle;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

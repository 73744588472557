#salon-list {
  .salon-list-header {
    text-align: center;
    padding-top: 40px;

    @include media-up(md){
      padding-top: 0;
    }

    .salon-list-header__title {
      h1 {
        font-size: 20px;
        font-weight: bold;
        font-family: $font-en;

        @include media-up(md){
          font-size: 30px;
        }
      }
    }

    .salon-list-header__text {
      margin-top: 30px;
      font-size: 12px;
      text-align: left;

      @include media-up(md){
        padding-right: 100px;
        padding-left: 100px;
        font-size: 14px;
        text-align: center;
        margin-top: 50px;
      }

      @include media-up(lg){
        padding-right: 150px;
        padding-left: 150px;
        font-size: 14px;
        text-align: center;
      }

      br {
        display: none;

        @include media-up(md){
          display: inline;
        }
      }

      .text {
        border: 1px solid #000;
        margin-top: 20px;
        display: block;
        width: 280px;
        text-align: center;
        font-size: 14px;
        padding: 10px;
        margin-left: auto;
        margin-right: auto;

        @include media-up(md){
          margin-top: 25px;
        }

        span {
          display: block;
          font-size: 12px;
        }
      }
    }
  }
  .salon-list-contents {
    margin-top: 30px;

    @include media-up(md){
      margin-top: 40px;
    }

    .salon-list-contents__list {
      display: flex;
      flex-wrap: wrap;
      margin-left: -10px;
      margin-top: 30px;

      @include media-up(md){
        margin-left: -30px;
        margin-top: 30px;
        margin-bottom: 100px;
      }

      li {
        width: calc(50% - 10px);
        margin-left: 10px;
        margin-top: 20px;

        @include media-up(md){
          width: calc(33.333% - 30px);
          margin-left: 30px;
          margin-top: 50px;
        }

        dl {
          dt{
            a {
              display: block;

              &:hover {
                opacity: 0.5;
                transition: all .1s ease;
              }
            }
            img {
              width: 100%;
              height: auto;
              vertical-align: middle;
            }
          }
          dd {
            text-align: center;
            margin-top: 10px;

            .name {
              font-size: 16px;
              font-weight: bold;
              font-family: $font-en;

              @include media-up(md){
                font-size: 20px;
              }
            }
            .adr {
              font-size: 10px;

              @include media-up(md){
                font-size: 12px;
              }
            }
            .tel {
              font-size: 14px;
              font-weight: bold;

              @include media-up(md){
                font-size: 20px;
                line-height: 1;
                margin-top: 5px;
              }
              span {
                font-size: 11px;

                @include media-up(md){
                  font-size: 14px;
                }
              }
            }
            .link {
              margin-top: 5px;
              display: flex;
              justify-content: center;

              @include media-up(md){
                margin-top: 15px;
              }

              a {
                margin: 0 3px;
                font-size: 10px;
                padding: 10px 7px;

                @include media-up(xxs){
                  padding: 5px 10px;
                  font-size: 12px;
                }


                @include media-up(md){
                  font-size: 18px;
                  margin: 0 6px;
                  padding: 10px 20px;
                }
              }
            }

          }
        }
      }
    }
  }

  .salon-list-history {
    background-color: rgb(45, 45, 45);
    margin-top: 50px;
    padding: 40px 0;
    color: $color-white;
    font-size: 18px;
    letter-spacing: 0.05em;
    line-height: 2;
    text-align: center;

    @include media-up(md){
      padding: 80px 0px;
      margin-top: 80px;
      font-size: 20px;
      line-height: 2.8;
    }

    br {
      @include media-up(md){
        display: none;
      }
    }

    .salon-list-history__inner {
      @include media-up(md){
        padding: 0 150px;
        text-align: center;
      }
    }

    .salon-list-contents__link {
      margin-top: 30px;
      width: 95%;
      max-width: 400px;
      margin-right: auto;
      margin-left: auto;

      @include media-up(md){
        margin-top: 50px;
      }

      a {
        text-align: center;
        font-size: 16px;
        padding: 15px;
        background: #000;

        @include media-up(md){
          font-size: 20px;
        }
      }
    }
  }

}


#salon {
  background: $color-white;
  // padding-top: 48px;

  .salon-cover {
    img {
      width: 100%;
      height: auto;
      vertical-align: middle;
      object-fit: cover;
      height: 300px;

      @include media-up(md){
        height: 400px;
      }

      @include media-up(lg){
        height: 500px;
      }
    }
  }

  .salon-header {
    text-align: center;
    margin-top: 30px;

    @include media-up(md){
      margin-top: 80px;
    }

    .salon-header__title {
      h1 {
        font-size: 20px;
        font-weight: bold;
        font-family: $font-en;

        @include media-up(md){
          font-size: 30px;
        }
      }
    }

    .salon-header__text {
      margin-top: 30px;
      font-size: 12px;
      text-align: left;

      @include media-up(md){
        padding-right: 100px;
        padding-left: 100px;
        font-size: 14px;
        text-align: center;
        margin-top: 50px;
        line-height: 2;
      }

      @include media-up(lg){
        padding-right: 150px;
        padding-left: 150px;
        font-size: 14px;
        text-align: center;
      }

      br {
        display: none;

        @include media-up(md){
          display: block;
        }
      }
    }
  }

  .salon-contents {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    padding-bottom: 30px;

    @include media-up(md){
      margin-top: 60px;
      flex-direction: row;
      justify-content: space-between;
    }

    .salon-gallery {
      @include media-up(md){
        width: calc(50% - 20px);
      }
      .salon-gallery-image__item {
        img {
          width: 100%;
          height: auto;
          vertical-align: middle;
        }
      }
    }

    .salon-gallery-thumbnail {
      margin-top: 10px;
      display: flex;
      flex-wrap: wrap;
      margin-left: -10px;

      .salon-gallery-thumbnail__item {
        width: calc(25% - 10px);
        margin-left: 10px;
        opacity: 0.25;

        @include media-up(md){
          width: calc(20% - 10px);
        }

        &.thumbnail-current {
          opacity: 1;
        }

        img {
          width: 100%;
          height: auto;
          vertical-align: middle;
        }
      }
    }

    .salon-information {
      margin-top: 30px;
      border-top: 1px solid #707070;
      border-bottom: 1px solid #707070;

      @include media-up(md){
        width: calc(50% - 20px);
        margin-top: 0;
      }

      dl {
        display: flex;
        padding: 20px 10px;
        font-size: 14px;

        @include media-up(md){
          padding: 20px;
          font-size: 15px;
        }

        + dl {
          border-top: 1px solid #707070;
        }
        dt {
          width: 120px;

          @include media-up(md){
            width: 150px;
          }
        }
        dd {
          width: calc(100% - 120px);

          @include media-up(md){
            width: calc(100% - 150px);
          }

          p {
            + p {
              margin-top: 10px;

              small {
                font-size: 10px;
                line-height: 1.6;
                display: block;

                @include media-up(md){
                  font-size: 12px;
                }
              }
            }
          }

          hr {
            border-top: 1px dotted #707070;

            @include media-up(md){
              margin: 20px 0;
            }
          }

          a {
            color: $color-main;

            &:hover {
              text-decoration: none;
            }
          }
        }
      }
    }
  }

  .salon-link {
    display: flex;
    padding-bottom: 50px;

    @include media-up(md){
      padding-top: 30px;
      max-width: 800px;
      padding-bottom: 70px;
    }


    a {
      width: calc(50% - 5px);
      // margin-left: 10px;
      text-align: center;
      font-size: 18px;
      padding: 10px 20px;

      @include media-up(md){
        padding: 25px;
        font-size: 22px;
        letter-spacing: 0.1em;
      }

      + a {
        margin-left: 10px;
      }
    }
  }

  .salon-schedule {
    background-color: $color-gray;
    padding: 50px 0;

    .salon-schedule__title {
      h2 {
        font-size: 16px;
        font-weight: bold;
        text-align: center;
      }
    }

    .salon-schedule__calendar {
      margin-top: 30px;
      display: flex;
      justify-content: center;

    }
  }

  .salon-list {
    padding-top: 30px;
    margin-bottom: 50px;

    @include media-up(md){
      padding-top: 60px;
      margin-bottom: 100px;
    }
    .salon-list__title {
      h2 {
        font-size: 16px;
        font-weight: bold;
        text-align: center;

        @include media-up(md){
          font-size: 30px;
        }
      }
    }

    .salon-list__list {
      display: flex;
      flex-wrap: wrap;
      margin-left: -10px;
      margin-top: 10px;

      @include media-up(md){
        margin-left: -30px;
        margin-top: 10px;
      }

      li {
        width: calc(50% - 10px);
        margin-left: 10px;
        margin-top: 20px;

        @include media-up(md){
          width: calc(33.333% - 30px);
          margin-left: 30px;
          margin-top: 50px;
        }

        dl {
          dt{
            a {
              display: block;
            }
            img {
              width: 100%;
              height: auto;
              vertical-align: middle;
            }
          }
          dd {
            text-align: center;
            margin-top: 10px;

            .name {
              font-size: 16px;
              font-weight: bold;
              font-family: $font-en;

              @include media-up(md){
                font-size: 20px;
              }
            }
            .adr {
              font-size: 10px;

              @include media-up(md){
                font-size: 12px;
              }
            }
            .tel {
              font-size: 14px;
              font-weight: bold;

              @include media-up(md){
                font-size: 20px;
                line-height: 1;
                margin-top: 5px;
              }
              span {
                font-size: 11px;

                @include media-up(md){
                  font-size: 14px;
                }
              }
            }
            .link {
              margin-top: 5px;
              display: flex;
              justify-content: center;

              @include media-up(md){
                margin-top: 15px;
              }

              a {
                margin: 0 3px;
                font-size: 10px;
                padding: 10px 7px;

                @include media-up(xxs){
                  padding: 5px 10px;
                  font-size: 12px;
                }


                @include media-up(md){
                  font-size: 18px;
                  margin: 0 6px;
                  padding: 10px 20px;
                }
              }
            }

          }
        }
      }
    }
  }
}

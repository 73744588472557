#privacy {
  background-color: $color-white;
  .privacy-header {
    background-image: url(../img/privacy/header_sp.jpg);
    background-size: cover;
    background-position: center center;

    @include media-up(md){
      background-image: url(../img/privacy/header_pc.jpg);
    }
  }

  a {
    color: $color-black;
    &:hover {
      text-decoration: none;
    }
  }
  .privacy-contents {
    margin-top: 30px;
    padding-bottom: 40px;

    @include media-up(md){
      margin-top: 50px;
      padding-bottom: 80px;
      padding-left: 50px;
      padding-right: 50px;
    }
  }

  .privacy-content-info {
    background-color: $color-white;

    + .privacy-content-info {
      margin-top: 40px;
    }

    @include media-up(md){
    }

    .privacy-content-info__title {
      padding-bottom: 20px;
      font-size: 18px;
      font-weight: bold;

      @include media-up(md){
        padding-bottom: 40px;
        font-size: 22px;
      }
    }

    .privacy-content-info__item {
      padding-bottom: 20px;

      + .privacy-content-info__item{
        border-top: 1px dotted #000;
      }

      h3 {
        margin-top: 20px;
        font-size: 18px;

        @include media-up(md){
          font-size: 20px;
        }
      }

      p {
        margin-top: 10px;
        font-size: 12px;

        @include media-up(md){
          font-size: 14px;
        }
      }

      ol {
        margin-top: 10px;
        font-size: 12px;
        list-style: inside;
        list-style-type: decimal;

        @include media-up(md){
          font-size: 14px;
        }

        li {
          text-indent: -1em;
          padding-left: 1em;
          margin-top: 10px;

          > ol {
            margin-left: 20px;
          }
        }
      }
    }


  }
}

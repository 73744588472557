#service {
  background: $color-white;
  min-height: 500px;

  .service-nav {
    background-color: #383838;
    color: #fff;
    padding: 10px 0px;
    font-size: 12px;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    position: fixed;
    top:75px;
    left:0;

    @include media-up(md){
      position: fixed;
      top: 50px;
      z-index: 100;
      width: 100%;
      padding: 15px 20px;
    }

    .service-nav__inner {
      display: flex;
      align-items: center;
      justify-content: center;
      // min-width: 370px;

      @include media-up(md){
        justify-content: flex-start;
        width: 100%;
        max-width: 1100px;
        padding-right: 25px;
        padding-left: 25px;
        margin-right: auto;
        margin-left: auto;
      }
    }

    p {
      margin-right: 20px;
      font-size: 10px;

      @include media-up(md){
        font-size: 12px;
        margin-right: 90px;
      }
    }

    ul {
      display: flex;

      li {
        margin: 0 10px;
        position: relative;
        cursor: pointer;
        border: 1px solid #fff;
        padding: 5px 15px;
        border-radius: 5px;

        @include media-up(md){
          margin: 0;
          margin-right: 20px;
        }
        // &::after {
        //   content:'';
        //   position: absolute;
        //   width: 100%;
        //   height: 2px;
        //   display: block;
        //   background-color: #383838;
        //   bottom: -10px;

        //   @include media-up(md){
        //     bottom: -15px;
        //   }
        // }

        &.is-active {
          // &::after {
          //   background-color: #fff;
          // }
          background-color: rgba(#fff,0.95);

          a {
            color: $color-black;
          }
        }

        a {
          color: $color-white;
          text-decoration: none;
        }
      }
    }
  }

  .service-contents {
    margin-top: 49px;

    @include media-up(md){
      margin-top: 49px;
    }
  }

  .service-contents__page {
    display: none;

    &.is-active {
      display: block;
    }
  }
}

// ひよこ
#panel1 {
  .service-header {
    background-image: url(../img/service/hiyoko-header_sp.jpg);
    background-size: cover;
    background-position: center center;

    @include media-up(md){
      background-image: url(../img/service/hiyoko-header_pc.jpg);
    }
  }

  .hiyoko-info {
    padding-top: 40px;
    padding-bottom: 40px;

    @include media-up(md){
      padding-top: 80px;
      padding-bottom: 80px;
    }
  }

  .hiyoko-info__title {
    h2 {
      font-size: 18px;
      font-weight: bold;
      text-align: center;

      @include media-up(md){
        font-size: 24px;
      }

      span {
        display: block;
        border: 1px solid #000;
        padding: 5px;
        margin-left: auto;
        margin-right: auto;
        width: 100px;
        font-size: 12px;
        margin-top: 10px;
      }
    }
  }

  .hiyoko-info__list {
    margin-top: 40px;
    display: flex;
    flex-direction: column;

    @include media-up(md){
      margin-top: 60px;
    }
  }

  .hiyoko-info__item {

    + .hiyoko-info__item {
      margin-top: 40px;

      @include media-up(md){
        margin-top: 60px;

        dl {
          flex-direction: row-reverse;
        }
      }
    }


    dl {
      display: flex;
      flex-direction: column;

      @include media-up(md){
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }

      dt {
        @include media-up(md){
          width: 340px;
        }
        img {
          width: 100%;
          height: auto;
          vertical-align: middle;
        }
      }

      dd {
        margin-top: 20px;

        @include media-up(md){
          margin-top: 0;
          width: calc(100% - 380px);
        }

        h3 {
          font-size: 16px;
          font-weight: bold;

          @include media-up(md){
            font-size: 22px;
          }
        }

        > p {
          margin-top: 10px;
          font-size: 14px;

          @include media-up(md){
            font-size: 16px;
          }
        }

        ul {
          display: flex;
          flex-direction: column;
          margin-top: 20px;

          li {
            display: flex;

            span {
              width: 25px;
              display: block;
            }

            p {
              width: calc(100% - 25px);
              padding-left: 10px;
              font-size: 14px;

              @include media-up(md){
                font-size: 14px;
              }

              br {
                display: none;

                @include media-up(md){
                  display: inherit;
                }
              }
            }

            + li {
              margin-top: 10px;

              @include media-up(md){
                margin-top: 15px;
              }
            }
          }
        }

        .caption {
          margin-top: 20px;
          font-size: 10px;
          color: #A0A0A0;

          @include media-up(md){
            font-size: 12px;
          }
        }
      }
    }
  }

  .hiyoko-info__caption {
    margin-top: 40px;
    border: 1px solid #000;
    padding: 20px;
    font-size: 12px;

    @include media-up(md){
      margin-top: 60px;
      padding: 20px 50px;
      max-width: 870px;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
    }

    br {
      display: none;

      @include media-up(md){
        display: inherit;
      }
    }
  }

  .hiyoko-flow {
    background-color: $color-gray;
    padding: 40px 0;

    @include media-up(md){
      padding: 80px 0;
    }
  }

  .hiyoko-flow__title {
    text-align: center;
    h2 {
      font-size: 18px;
      font-weight: bold;

      @include media-up(md){
        font-size: 24px;
      }
    }
  }

  .hiyoko-flow__step {
    margin-top: 30px;
    margin-bottom: 50px;

    @include media-up(md){
      margin-top: 50px;
      margin-bottom: 100px;
    }

    ul {

      li {
        background-color: $color-white;
        display: flex;

        + li {
          margin-top: 20px;
        }

        dl {
          display: flex;

          dt {
            width: 80px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-right: 2px solid $color-gray;
            font-family: $font-en;
            font-size: 30px;
            font-weight: bold;

            @include media-up(md){
              height: 80px;
            }
          }

          dd {
            width: calc(100% - 80px);
            padding: 20px;
            font-size: 12px;

            @include media-up(md){
              font-size: 14px;
              display: flex;
              align-items: center;
            }

            br {
              display: none;

              @include media-up(md){
                display: inline;
              }
            }
          }
        }
      }
    }
  }

  .hiyoko-flow__list {
    margin-top: 30px;

    @include media-up(md){
      margin-top: 60px;
    }

    ul {
      display: flex;
      flex-direction: column;

      @include media-up(md){
        flex-direction: row;
        justify-content: center;
      }

      li {
        @include media-up(md){
          margin: 0 20px;
        }

        + li {
          margin-top: 40px;

          @include media-up(md){
            margin-top: 0;
          }
        }

        dl {
          dt {
            img {
              width: 100%;
              height: auto;
              vertical-align: middle;
            }
          }

          dd {
            margin-top: 10px;

            h3 {
              font-size: 18px;
              font-weight: bold;
              text-align: center;

              @include media-up(md){
                font-size: 24px;
              }
            }

            p {
              text-align: center;
            }

            .link {
              margin-top: 10px;

              a {
                max-width: 200px;
                margin-left: auto;
                margin-right: auto;
                text-align: center;
                padding: 10px 0;
              }
            }

          }
        }
      }
    }
  }

  .hiyoko-item {
    padding-top: 40px;
    padding-bottom: 40px;

    @include media-up(md){
      padding-top: 80px;
      padding-bottom: 80px;
    }

    .hiyoko-item__title {
      text-align: center;
      h2 {
        font-size: 18px;
        font-weight: bold;

        @include media-up(md){
          font-size: 24px;
        }

        span {
          display: block;
          border: 1px solid #000;
          padding: 5px;
          margin-left: auto;
          margin-right: auto;
          width: 120px;
          font-size: 12px;
          margin-top: 10px;
        }
      }
    }

    .hiyoko-item__list {
      margin-top: 30px;

      @include media-up(md){
        margin-top: 60px;
      }

      ul {
        @include media-up(md){
          max-width: 870px;
          width: 100%;
          margin-left: auto;
          margin-right: auto;
        }

        li {
          + li {
            margin-top: 20px;
          }

          dl {
            border: 1px solid #000;
            display: flex;
            flex-direction: column;

            @include media-up(md){
              flex-direction: row;
            }

            dt {
              border-bottom: 1px solid #000;
              padding: 10px;
              text-align: center;
              font-size: 16px;
              font-weight: bold;

              @include media-up(md){
                width: 200px;
                border-bottom: 0px solid #000;
                border-right: 1px solid #000;
                padding: 20px;
                font-size: 20px;
              }
            }

            dd {
              padding: 10px;
              font-size: 14px;

              @include media-up(md){
                display: flex;
                align-items: center;
                padding: 20px;
                font-size: 16px;
              }
            }
          }
        }
      }
    }

    .hiyoko-item__caption {
      margin-top: 20px;
      font-size: 12px;
      color: #A0A0A0;

      @include media-up(md){
        margin-top: 30px;
        max-width: 870px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
      }
    }

    .hiyoko-item__alert {
      border: 1px solid #FF0045;
      padding: 10px;
      margin-top: 20px;
      color: #FF0045;
      font-size: 12px;
      padding: 10px;
      text-align: center;

      @include media-up(md){
        margin-top: 30px;
        max-width: 500px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  .hiyoko-faq {
    background-color: $color-gray;
    padding-top: 40px;
    padding-bottom: 40px;

    @include media-up(md){
      padding-top: 80px;
      padding-bottom: 80px;
    }

    .hiyoko-faq__title {
      text-align: center;
      h2 {
        font-size: 18px;
        font-weight: bold;

        @include media-up(md){
          font-size: 24px;
        }
      }
    }

    .hiyoko-faq__list {
      margin-top: 30px;

      @include media-up(md){
        margin-top: 60px;
      }
    }

    .hiyoko-faq__item {

      + .hiyoko-faq__item {
        margin-top: 70px;
      }
      .question {
        background-color: $color-white;
        border-radius: 20px;
        padding: 20px;
        position: relative;

        @include media-up(md){
          width: calc(100% - 45%);
          margin-right: 45%;
        }

        &::after {
          content:'';
          width: 40px;
          height: 40px;
          background-image: url(../img/service/figure-cursor-left.svg);
          display: block;
          background-size: 100% auto;
          background-repeat: no-repeat;
          position: absolute;
          bottom: -35px;
          left:0;
        }

        dl {
          display: flex;
          align-items: center;

          dt {
            width: 40px;
            height: 40px;
            font-size: 30px;
            font-family: $font-en;
            color: #FF0045;
            line-height: 1;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          dd {
            width: calc(100% - 40px);
            padding-left: 10px;
            font-size: 12px;

            @include media-up(md){
              font-size: 14px;
            }
          }
        }
      }

      .anser {
        margin-top: 30px;
        background-color: $color-white;
        border-radius: 20px;
        padding: 20px;
        position: relative;

        @include media-up(md){
          width: calc(100% - 45%);
          display: flex;
          position: relative;
          margin-left: 45%;
        }

        &::after {
          content:'';
          width: 40px;
          height: 40px;
          background-image: url(../img/service/figure-cursor-right.svg);
          display: block;
          background-size: 100% auto;
          background-repeat: no-repeat;
          position: absolute;
          bottom: -35px;
          right:0;
        }

        dl {
          display: flex;
          align-items: center;

          dt {
            width: 40px;
            height: 40px;
            font-size: 30px;
            font-family: $font-en;
            line-height: 1;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          dd {
            width: calc(100% - 40px);
            padding-left: 10px;
            font-size: 12px;

            @include media-up(md){
              font-size: 14px;
            }
          }
        }
      }
    }
  }

  .hiyoko-countermeasures {
    padding-top: 40px;
    padding-bottom: 40px;

    @include media-up(md){
      padding-top: 80px;
      padding-bottom: 80px;
    }

    .hiyoko-countermeasures__title {
      text-align: center;
      h2 {
        font-size: 18px;
        font-weight: bold;

        @include media-up(md){
          font-size: 24px;
        }
      }
    }
    .hiyoko-countermeasures__figure {
      margin-top: 30px;
      max-width: 500px;
      width: 100%;
      margin-left: auto;
      margin-right: auto;

      @include media-up(md){
        margin-top: 60px;
      }

      img {
        width: 100%;
        height: auto;
        vertical-align: middle;
      }
    }
  }
}

// パーソナルカラー
#panel2 {
  .service-header {
    background-image: url(../img/service/color-header_sp.jpg);
    background-size: cover;
    background-position: center center;

    @include media-up(md){
      background-image: url(../img/service/color-header_pc.jpg);
    }

    .en {
      text-align: center;
    }
  }

  .color-info {
    padding-top: 40px;
    padding-bottom: 40px;

    @include media-up(md){
      padding-top: 80px;
      padding-bottom: 80px;
    }
  }

  .color-info__title {
    h2 {
      font-size: 18px;
      font-weight: bold;
      text-align: center;

      @include media-up(md){
        font-size: 24px;
      }

      span {
        display: block;
        border: 1px solid #000;
        padding: 5px;
        margin-left: auto;
        margin-right: auto;
        width: 100px;
        font-size: 12px;
        margin-top: 10px;
      }
    }
  }

  .color-info__copy {
    margin-top: 30px;
    background-color: #FF769B;
    padding: 20px;

    @include media-up(md){
      padding: 40px 100px;
      margin-top: 60px;
    }

    h3 {
      font-size: 16px;
      color: $color-white;

      @include media-up(md){
        text-align: center;
        font-size: 20px;
      }
    }

    p {
      margin-top: 10px;
      font-size: 14px;

      @include media-up(md){
        margin-top: 15px;
        text-align: center;
      }

      br {
        display: none;

        @include media-up(md){
          display: inline;
        }
      }
    }
  }

  .color-whats {
    margin-top: 30px;
    padding-bottom: 40px;

    @include media-up(md){
      padding-bottom: 80px;
    }
    h2 {
      font-size: 18px;
      font-weight: bold;
      text-align: center;

      @include media-up(md){
        font-size: 24px;
      }

      br {
        @include media-up(md){
          display: none;
        }
      }
    }

    p {
      margin-top: 20px;
      @include media-up(md){
        margin-top: 40px;
        font-size: 16px;
      }
    }
  }

  .color-howto {
    background-color: $color-gray;
    padding-top: 40px;
    padding-bottom: 40px;

    @include media-up(md){
      padding-top: 80px;
      padding-bottom: 80px;
    }

    h2 {
      font-size: 18px;
      font-weight: bold;
      text-align: center;

      @include media-up(md){
        font-size: 24px;
      }

      br {
        @include media-up(md){
          display: none;
        }
      }
    }

    .color-howto__contents {
      margin-top: 30px;

      @include media-up(md){
        display: flex;
        margin-top: 60px;
      }
    }

    .color-howto__flow {

      @include media-up(md){
        max-width: 680px;
      }
      dl {
        display: flex;
        background-color: #fff;

        + dl {
          margin-top: 20px;
        }

        dt {
          width: 80px;
          display: flex;
          align-items: center;
          justify-content: center;

          font-family: $font-en;
          font-size: 30px;
          font-weight: bold;

          @include media-up(md){
          }
        }

        dd {
          width: calc(100% - 80px);
          padding: 20px;
          font-size: 12px;
          border-left: 2px solid $color-gray;

          @include media-up(md){
            font-size: 14px;
          }

          h3 {
            font-size: 14px;
            font-weight: bold;

            @include media-up(md){
              font-size: 20px;
            }

            br {
              display: none;

              @include media-up(md){
                display: inline;
              }
            }
          }

          p {
            margin-top: 10px;

            @include media-up(md){
              font-size: 14px;
            }
          }
        }
      }
    }

    .color-howto__image {
      margin-top: 20px;

      @include media-up(md){
        margin-top: 0;
        padding-left: 40px;
      }

      img {
        width: 100%;
        height: auto;
        vertical-align: middle;
      }
    }
  }

  .color-check {
    padding-top: 40px;
    padding-bottom: 40px;

    @include media-up(md){
      padding-top: 80px;
      padding-bottom: 80px;
    }

    .color-check__title {
      h2 {
        font-size: 18px;
        font-weight: bold;
        text-align: center;

        @include media-up(md){
          font-size: 24px;
        }

        span {
          display: block;
          border: 1px solid #000;
          padding: 5px;
          margin-left: auto;
          margin-right: auto;
          width: 220px;
          font-size: 12px;
          margin-top: 10px;
        }
      }
    }

    .color-check-item {
      border: 1px solid #000;

      + .color-check-item {
        margin-top: 30px;
      }

      @include media-up(md){
        margin-left: auto;
        margin-right: auto;
        max-width: 870px;
        margin-top: 30px;
      }

      .color-check-item__header {
        border-bottom: 1px solid #000;

        @include media-up(md){
          display: flex;
          align-items: center;
        }

        .no {
          padding: 10px 10px 0;
          font-size: 16px;
          font-weight: bold;
          text-align: center;

          @include media-up(md){
            width: 210px;
            padding: 20px;
            font-size: 20px;
            border-right: 1px solid #000;
          }
        }

        .title {
          padding: 0 10px 10px;
          text-align: center;

          @include media-up(md){
            padding: 20px;
            font-size: 20px;
            font-weight: bold;
          }
        }
      }

      .color-check-item__contents {
        padding: 20px 10px;

        @include media-up(md){
          padding: 30px;
        }

        .step1 {
          @include media-up(md){
            display: flex;
            justify-content: space-between;

            .color-list {
              width: 47%;
            }
          }
        }

        .step2 {
          @include media-up(md){
            display: flex;
            justify-content: space-between;

            .color-list {
              width: 47%;
            }
          }

          + .step2 {
            border-top: 1px dotted #A0A0A0;
            padding-top: 40px;
            margin-top: 40px;

            @include media-up(md){
              margin-top: 10px;
            }
          }

          .link {
            margin-top: 15px;
            margin-bottom: 30px;
            max-width: 150px;
            margin-left: auto;
            margin-right: auto;

            a {
              padding: 10px;
            }
          }
        }

        .color-list {
          text-align: center;

          + .color-list {
            margin-top: 20px;

            @include media-up(md){
              margin-top: 0;
            }
          }
          .name {
            display: inline-block;
            border: 1px solid #000;
            padding: 0 5px;
            min-width: 100px;
          }
          ul {
            margin-top: 20px;
            display: flex;
            justify-content: center;

            li {
              width: 25%;
              dl {
                dt {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  span {
                    background-color: red;
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    display: block;

                    @include media-up(md){
                      width: 70px;
                      height: 70px;
                    }

                    &.c-ff590B { background-color: #ff590B;}
                    &.c-535617 { background-color: #535617;}
                    &.c-eeac00 { background-color: #eeac00;}
                    &.c-642a2e { background-color: #642a2e;}
                    &.c-fd0390 { background-color: #fd0390;}
                    &.c-007e77 { background-color: #007e77;}
                    &.c-00509D { background-color: #00509D;}
                    &.c-292929 { background-color: #292929;}
                    &.c-ee7948 { background-color: #ee7948;}
                    &.c-ff9e7d { background-color: #ff9e7d;}
                    &.c-caca61 { background-color: #caca61;}
                    &.c-edd267 { background-color: #edd267;}
                    &.c-00926E { background-color: #00926E;}
                    &.c-666699 { background-color: #666699;}
                    &.c-FCC7D8 { background-color: #FCC7D8;}
                    &.c-535617 { background-color: #535617;}
                    &.c-DD7262 { background-color: #DD7262;}
                    &.c-F9C20D { background-color: #F9C20D;}
                    &.c-007672 { background-color: #007672;}
                    &.c-451B04 { background-color: #451B04;}
                    &.c-5F2B0F { background-color: #5F2B0F;}
                    &.c-F2E3C6 { background-color: #F2E3C6;}
                    &.c-CA312B { background-color: #CA312B;}
                    &.c-D04528 { background-color: #D04528;}
                    &.c-EBA59B { background-color: #EBA59B;}
                    &.c-F8EDAB { background-color: #F8EDAB;}
                    &.c-E4E565 { background-color: #E4E565;}
                    &.c-1BA7B6 { background-color: #1BA7B6;}
                    &.c-C69F38 { background-color: #C69F38;}
                    &.c-FDF8F4 { background-color: #FDF8F4;}
                    &.c-CDC28C { background-color: #CDC28C;}
                    &.c-CD316E { background-color: #CD316E;}
                    &.c-FD0390 { background-color: #FD0390;}
                    &.c-F4EC41 { background-color: #F4EC41;}
                    &.c-01926D { background-color: #01926D;}
                    &.c-0058A0 { background-color: #0058A0;}
                    &.c-9B7D5B { background-color: #9B7D5B;}
                    &.c-070101 { background-color: #070101;}
                    &.c-1C3D6A { background-color: #1C3D6A;}
                    &.c-FCC7D8 { background-color: #FCC7D8;}
                    &.c-FCE7B3 { background-color: #FCE7B3;}
                    &.c-007E77 { background-color: #007E77;}
                    &.c-73A5C1 { background-color: #73A5C1;}
                    &.c-922B2A { background-color: #922B2A;}
                    &.c-D5D5D7 { background-color: #D5D5D7;}
                    &.c-FFFFFF { background-color: #FFFFFF; border: 1px solid #ccc;}
                  }
                }

                dd {
                  margin-top: 5px;
                  font-size: 10px;
                  min-height: 35px;
                }
              }
            }
          }
        }

        .type-info {
          display: flex;
          flex-direction: column;
          padding: 0 5px 20px;

          @include media-up(md){
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
          }

          .type-info__text {
            @include media-up(md){
              width: 49%;
            }
            h3 {
              font-size: 18px;
              font-weight: bold;

              @include media-up(md){
                font-size: 20px;
              }
            }

            p {
              font-size: 14px;
              margin-top: 5px;
            }

            table {
              margin-top: 20px;
              border-top: 1px dotted #000;
              font-size: 10px;

              @include media-up(md){
                width: 100%;
                font-size: 12px;
                margin-top: 30px;
              }
              tr {
                border-bottom: 1px dotted #000;
                th {
                  border-right: 1px dotted #000;
                  vertical-align: middle;
                  padding: 5px;

                  @include media-up(md){
                    padding: 10px;
                    min-width: 110px;
                  }
                }

                td {
                  padding: 5px;
                  vertical-align: middle;

                  @include media-up(md){
                    padding: 10px;
                  }
                }
              }

            }
          }

          .type-info__color {
            margin-top: 30px;

            @include media-up(md){
              margin-top: 0;
              width: 49%;
            }

            ul {
              flex-wrap: wrap;
              li {
                width: 25%;
              }
            }
          }
        }
      }
    }
  }
}

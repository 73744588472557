#index {
  margin-top: 75px;

  @include media-up(md){
    margin-top: 50px;
  }

  .index-cover {
    position: relative;

    .index-cover-slider {
      margin-bottom: 0;
    }

    .index-cover-slider__item {
      vertical-align: middle;
      img, video {
        height: 70vh;
        width: 100%;
        object-fit: cover;
        object-position: center;
        vertical-align: middle;

        @include media-up(md){
          height: 500px;
          object-fit: cover;
        }

        @include media-up(lg){
          width: 100%;
          height: auto;
          vertical-align: middle;
          height: 720px;
        }
      }
    }

    .js-slider-dots {
      position: absolute;
      bottom: 20px;
      right: 20px;
      display: flex;
      flex-direction: column;

      li {
        width: 10px;
        height: 10px;
        display: block;
        background-color: $color-white;
        opacity: 0.25;

        + li {
          margin-top: 10px;
        }

        &.slick-active {
          opacity: 1;
        }
      }

      button{
        margin: 0;
        padding: 0;
        background: none;
        border: none;
        border-radius: 0;
        outline: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        position: relative;
        text-indent: -9999px;
        &:before{
          content: '';
          font-size: 20px;
          text-indent: 0px;
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }
  }

  .index-news {
    padding: 20px 0;
    background-color: $color-white;
    .index-news__inner {
      position: relative;
      display: flex;
      flex-direction: column;
      padding-top: 15px;
      padding-bottom: 15px + 25px;
      margin-left: 20px;
      width: calc(100% - 20px);
      border-left: 4px solid #EAEAEA;

      @include media-up(md){
        flex-direction: row;
        align-items: center;
        margin-left: auto;
        border-left: 0px solid #000;
        padding-top: 20px;
        padding-bottom: 20px;
        width: 100%;
      }
    }

    .index-news__title {
      @include media-up(md){
        width: 120px;
      }
      h2 {
        font-family: $font-en;
        font-weight: bold;
        font-size: 18px;
        letter-spacing: 0.03em;

        @include media-up(md){
          font-size: 22px;
        }

      }
    }

    .index-news__contents {
      margin-top: 0px;

      @include media-up(md){
        width: calc(100% - 120px);
        border-left: 4px solid #EAEAEA;
        padding-left: 40px;
        padding-top: 15px;
        padding-bottom: 15px;
      }

      ul {
        display: flex;
        flex-direction: column;

        li {

          @include media-up(md){
            border-bottom: 1px dotted #EAEAEA;
            padding-bottom: 5px;
          }

          + li {
            margin-top: 10px;

            @include media-up(md){
              margin-top: 15px;
            }
          }

          dl {
            display: flex;
            flex-direction: column;

            @include media-up(md){
              flex-direction: row;
            }

            dt {
              width: 80px;
              font-family: $font-en;
              font-weight: bold;
              font-size: 12px;
              margin-top: 3px;

              @include media-up(md){
                font-size: 14px;
                width: 100px;
                margin-top: 0;
              }
            }

            dd {
              width: calc(100%);
              font-size: 12px;

              @include media-up(md){
                font-size: 14px;
                width: calc(100% - 100px);
              }

              a {
                text-decoration: underline;
                color: $color-black;

                @include media-up(md){
                  text-decoration: none;
                  transition: all 0.1s ease;

                  &:hover {
                    opacity: 0.5;
                  }
                }
              }
            }
          }
        }
      }
    }

    .index-news__link {
      position: absolute;
      bottom: 0;
      right:20px;

      a {
        color: $color-black;

        @include media-up(md){
          text-decoration: none;
          transition: all .2s ease;
          opacity: 1;

          &:hover {
            opacity: 0.5;
          }
        }
      }
    }
  }

  .index-pickup {
    background-color: $color-gray;

    .index-pickup__inner {
      padding-top: 45px;
      padding-bottom: 25px;

      @include media-up(md){
        padding-top: 60px;
        padding-bottom: 60px;
      }
    }

    .index-pickup__title {
      h2 {
        text-align: center;
        font-family: $font-en;
        font-weight: bold;
        font-size: 20px;

        @include media-up(md){
          font-size: 30px;
        }
      }
    }

    .index-pickup__contents {
      padding: 0;
      margin-top: 30px;
      padding-left: 25px;
      overflow-x: auto;
      white-space: nowrap;
      overflow-scrolling: touch;
      -webkit-overflow-scrolling: touch;

      @include media-up(md){
        width: 100%;
        overflow: hidden;
        padding-left: 0;
        position: relative;
        margin-top: 50px;
      }

      .index-pickup__item {
        display: inline-block;
        width: 85%;
        padding-right: 15px;
        padding-bottom: 20px;

        @include media-up(md){
          height: auto;
          display: block;
          padding-bottom: 50px;

          &:hover a  {
            opacity: 0.5;
            transition: all 0.2s ease;
          }
        }


        img {
          width: 100%;
          height: auto;
          vertical-align: middle;
        }

        dl {
          position: relative;
          dt {
            a {
              display: block;

              img {
                border-radius: 20px;
              }
            }
          }

          dd {
            display: flex;
            flex-direction: column;
            width: 100%;
            margin: auto;
            background-color:rgba($color-white,0.9);
            position: absolute;
            bottom: 0px;
            left:0;
            right:0;
            white-space: normal;
            opacity: 1;
            align-items: center;
            padding: 15px 20px 15px;
            border-radius: 0 0 20px 20px;

            h3 {
              width: 100%;
              font-size: 18px;
              font-weight: bold;
              text-align: center;
              margin-bottom: 10px;
            }

            p {
              font-size: 10px;
              display: inherit;
              min-height: 50px;
            }


            @include media-up(md){
              display: flex;
              flex-direction: row;
              width: 80%;
              margin: auto;
              background-color:$color-white;
              position: absolute;
              bottom: -60px;
              left:0;
              right:0;
              white-space: normal;
              opacity: 1;
              transition: all 0.5s ease;
              align-items: center;
              padding: 20px 20px 25px;
              border-radius: 0;

              h3 {
                width: 40%;
                font-size: 18px;
                font-weight: bold;
                text-align: center;
                margin-bottom: 0;
              }

              p {
                width: 60%;
                padding-left: 10px;
                font-size: 12px;
                display: inherit;
                min-height: auto;
              }
            }

            @include media-up(xxxl){
              opacity: 1;
            }
          }
        }
      }

      .slick-slide {
        opacity: 1;
        transition: all 0.5s ease;

        @include media-up(xxxl){
          opacity: 1;
        }

        &.slick-active {
          opacity: 1;
          overflow: visible !important;

          dd {
            opacity: 1;
          }
        }

      }

      .slide-arrow {
        position: absolute;
        top:20px;
        bottom:0;
        background-color: $color-white;
        border-radius: 50%;
        margin: auto;
      }

      .prev-arrow {
        z-index: 100;
        left: -60px;
        display: block;
        width: 100px;
        height: 100px;
        background-image: url(./assets/img/index/cursor-left.svg);
        background-repeat: no-repeat;
        background-position: right 20px center;
        background-size: 8px auto;
        cursor: pointer;
        transition: all .1s ease;


      }

      .next-arrow {
        z-index: 100;
        right: -60px;
        display: block;
        width: 100px;
        height: 100px;
        background-image: url(./assets/img/index/cursor-right.svg);
        background-repeat: no-repeat;
        background-position: left 20px center;
        background-size: 8px auto;
        cursor: pointer;
        transition: all .1s ease;

      }
    }



  }

  .index-middlebanner {
    padding-top: 35px;
    padding-bottom: 35px;
    border-bottom: 1px solid $color-gray;

    @include media-up(md){
      padding-top: 75px;
      padding-bottom: 55px;
    }
    ul {
      display: flex;
      flex-direction: column;

      @include media-up(md){
        flex-direction: row;
        flex-wrap: wrap;
      }

      li {
        width: 100%;

        @include media-up(md){
          width: 33.333%;
          margin-bottom: 20px;
        }

        + li {
          margin-top: 20px;
          @include media-up(md){
            margin-top: 0;
          }
        }
        dl {
          @include media-up(md){
            padding: 0 10px;
          }
          dt {
            a {
              display: block;
              @include media-up(md){
                opacity: 1;
                transition: all .1s ease;
                cursor: pointer;

                &:hover {
                  opacity: 0.5;
                }
              }
              img {
                width: 100%;
                height: auto;
                vertical-align: middle;
              }
            }
          }
          dd {
            text-align: center;
            margin-top: 5px;
            font-size: 12px;
            font-weight: bold;

            @include media-up(md){
              font-size: 14px;
            }
          }
        }
      }
    }
  }

  .index-salon {
    .index-salon__inner {
      padding-top: 45px;
      padding-bottom: 45px;

      @include media-up(md){
        padding-top: 80px;
        padding-bottom: 80px;
      }
    }

    .index-salon__title {
      h2 {
        text-align: center;
        font-family: $font-en;
        font-weight: bold;
        font-size: 20px;

        @include media-up(md){
          font-size: 30px;
        }
      }
    }

    .index-salon__list {
      display: flex;
      flex-wrap: wrap;
      margin-left: -10px;
      margin-top: 30px;

      @include media-up(md){
        margin-left: -30px;
        margin-top: 30px;
      }

      li {
        width: calc(50% - 10px);
        margin-left: 10px;
        margin-top: 20px;

        @include media-up(md){
          width: calc(33.333% - 30px);
          margin-left: 30px;
          margin-top: 50px;
        }

        dl {
          dt{
            a {
              display: block;
            }
            img {
              width: 100%;
              height: auto;
              vertical-align: middle;
            }
          }
          dd {
            text-align: center;
            margin-top: 10px;

            .name {
              font-size: 16px;
              font-weight: bold;
              font-family: $font-en;

              @include media-up(md){
                font-size: 20px;
              }
            }
            .adr {
              font-size: 10px;

              @include media-up(md){
                font-size: 12px;
              }
            }
            .tel {
              font-size: 14px;
              font-weight: bold;

              @include media-up(md){
                font-size: 20px;
                line-height: 1;
                margin-top: 5px;
              }
              span {
                font-size: 11px;

                @include media-up(md){
                  font-size: 14px;
                }
              }
            }
            .link {
              margin-top: 5px;
              display: flex;
              justify-content: center;

              @include media-up(md){
                margin-top: 15px;
              }

              a {
                margin: 0 3px;
                font-size: 10px;
                padding: 10px 7px;

                @include media-up(xxs){
                  padding: 5px 10px;
                  font-size: 12px;
                }


                @include media-up(md){
                  font-size: 13px;
                  margin: 0 4px;
                  padding: 10px 15px;
                }
              }
            }

          }
        }
      }
    }
  }

  .index-sns {
    border-top: 1px solid $color-gray;
    .index-sns__inner {
      padding-top: 45px;
      padding-bottom: 45px;

      @include media-up(md){
        padding-top: 60px;
        padding-bottom: 60px;
      }
    }

    .index-sns__title {
      h2 {
        text-align: center;
        font-family: $font-en;
        font-weight: bold;
        font-size: 20px;

        @include media-up(md){
          font-size: 30px;
        }
      }
    }

    .index-sns__list {
      display: flex;
      justify-content: center;
      margin-top: 40px;

      @include media-up(md){
        margin-top: 50px;
      }

      li {
        margin: 0 10px;

        @include media-up(md){
          margin: 0 15px;
        }

        a {
          display: block;

          @include media-up(md){
            opacity: 1;
            transition: all .1s ease;

            &:hover {
              opacity: 0.5;
            }
          }

          img {
            height: 25px;
            width: auto;

            @include media-up(md){
              height: 35px;
            }
          }
        }
      }
    }
  }
}

// コンテナ
.c-container {
  width: 100%;
  max-width: 1100px;
  padding-right: 25px;
  padding-left: 25px;
  margin-right: auto;
  margin-left: auto;

  @include media-up(md) {
    padding-right: 25px;
    padding-left: 25px;
  }
}

.c-page {
  background-color: $color-gray;
  padding-top: 100px;

  @include media-up(md){
    padding-top: 120px;
  }
}

.c-link {
  background-color: $color-black;
  color: $color-white;
  font-size: 12px;
  font-weight: bold;
  padding: 5px 10px;
  display: block;
  text-decoration: none;
  border-radius: 5px;

  @include media-up(md){
    font-size: 18px;
    padding: 10px 20px;
    opacity: 1;
    transition: all .1s ease;

    &:hover {
      opacity: 0.5;
    }
  }

  &.is--blue {
    background-color: $color-darkgray;
  }

}

.c-pageheader {
  height: 200px;
  background-color: #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @include media-up(md){
    margin-top: -70px;
    height: 345px;
    align-items: flex-start;
  }

  h1 {
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0.1em;

    &.en {
      font-family: $font-en;
    }

    @include media-up(md){
      font-size: 30px;
      width: 100%;
      max-width: 1100px;
      padding-right: 25px;
      padding-left: 25px;
      margin-right: auto;
      text-align: center;
      margin-left: auto;
      // padding-left: 40px;
    }
  }

  p {
    font-size: 14px;
    font-weight: bold;

    @include media-up(md){
      font-size: 20px;
      width: 100%;
      max-width: 1100px;
      padding-right: 25px;
      padding-left: 25px;
      margin-right: auto;
      margin-left: auto;
      text-align: center;
      // padding-left: 40px;
    }
  }
}

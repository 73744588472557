#recruit {
  background: $color-white;

  .recruit-header {
    background-image: url(../img/recruit/header_sp.jpg);
    background-size: cover;
    background-position: center center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 300px;


    @include media-up(md) {
      background-image: url(../img/recruit/header_pc.jpg);
      height: 500px;
    }
  }

  .recruit-header-banner {
    background-color: $color-white;
    width: 200px;
    padding: 20px;
    outline: 3px solid rgb(0, 0, 0);
    outline-offset: -7px;

    @include media-up(md) {
      width: 330px;
      padding: 40px;
    }

    h1 {
      text-align: center;

      &:after {
        content: '';
        display: block;
        width: 100px;
        height: 1px;
        margin: 8px auto;
        border-top: 1px solid #000;

        @include media-up(md) {
          margin: 15px auto;
        }
      }

      span {
        display: block;
        font-size: 14px;
        font-weight: normal;

        @include media-up(md) {
          font-size: 20px;
          font-weight: bold;
        }
      }
    }

    p {
      text-align: center;
      font-size: 20px;
      line-height: 1;

      @include media-up(md) {
        font-size: 30px;
        letter-spacing: 0.1em;
      }
    }
  }

  .recruit-copy {
    margin-top: 45px;

    @include media-up(md) {
      margin-top: 90px;
    }

    h2 {
      font-size: 18px;
      font-weight: bold;
      text-align: center;

      @include media-up(md) {
        font-size: 24px;
      }

      br {
        @include media-up(md) {
          display: none;
        }
      }
    }

    p {
      font-size: 14px;
      margin-top: 35px;

      @include media-up(md) {
        font-size: 16px;
        margin-top: 75px;
        line-height: 2;
        text-align: center;
      }

      br {
        display: none;

        @include media-up(md) {
          display: inherit;
        }
      }
    }
  }

  .recruit-point {
    background-color: #F7F7F7;
    margin-top: 40px;
    padding: 20px 0;

    @include media-up(md) {
      margin-top: 80px;
    }
  }

  .recruit-point-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    @include media-up(md) {
      padding: 80px 0;
      padding-bottom: 40px;
    }
  }

  .recruit-point-item {
    background-color: $color-white;
    padding: 30px;
    margin-bottom: 20px;

    @include media-up(md) {
      width: calc(33.333% - 30px);
      margin-left: 30px;
      margin-bottom: 30px;
    }

    dl {
      dt {
        text-align: center;
        padding-top: 20px;

        span {
          font-size: 70px;

          @include media-up(md) {
            font-size: 50px;
          }
        }
      }

      dd {
        margin-top: 20px;

        h2 {
          text-align: center;
          font-size: 16px;
          font-weight: bold;
        }

        p {
          margin-top: 10px;
          font-size: 13px;
        }
      }
    }
  }

  .recruit-message {
    margin-top: 40px;
    padding-bottom: 60px;

    @include media-up(md) {
      margin-top: 80px;
      padding-bottom: 100px;
    }

    dl {
      display: flex;
      flex-direction: column;
      margin-top: 20px;

      @include media-up(md) {
        flex-direction: row;
        margin-top: 50px;
      }

      dt {
        @include media-up(md) {
          width: 330px;
        }

        img {
          vertical-align: middle;
        }
      }

      dd {
        margin-top: 20px;

        @include media-up(md) {
          width: calc(100% - 330px);
          padding-left: 30px;
        }

        h3 {
          font-size: 18px;
          text-align: center;

          @include media-up(md) {
            text-align: left;
            font-size: 17px;
          }
        }

        p {
          font-size: 13px;
          margin-top: 20px;
        }
      }
    }
  }

  .recruit-message__title {
    text-align: center;
    font-size: 20px;
    font-family: $font-en;
    font-weight: bold;

    @include media-up(md) {
      font-size: 24px;
    }
  }

  .recruit-salonmap {
    margin-top: 40px;
    margin-bottom: -80px;
    padding: 40px;
    outline: 1px solid rgb(0, 0, 0);
    outline-offset: -20px;

    @include media-up(md) {
      margin-top: 80px;
      margin-bottom: -50px;
      padding: 80px;
    }
  }

  .recruit-salonmap__title {
    text-align: center;

    h2 {
      font-size: 20px;
      font-weight: bold;

      @include media-up(md) {
        font-size: 24px;
      }
    }

    p {
      margin-top: 20px;
      text-align: center;
      font-size: 13px;
    }

  }

  .recruit-banner {
    margin-top: -80px;
    background-color: #646464;
    padding: 20px 0;
    color: $color-white;

    @include media-up(md) {
      margin-top: -30px;
    }

    dl {
      display: flex;
      flex-direction: column;

      @include media-up(md) {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 30px 0;
      }

      dt {
        h2 {
          font-size: 14px;
          font-weight: bold;

          @include media-up(md) {
            font-size: 20px;
          }
        }

        p {
          font-size: 13px;
          margin-top: 10px;

          br {
            display: none;

            @include media-up(md) {
              display: inherit;
            }
          }
        }
      }

      dd {
        margin-top: 20px;

        a {
          display: block;
          background-color: #313131;
          width: 270px;
          padding: 20px;
          font-size: 20px;
          color: $color-white;
          text-align: center;
          border-radius: 5px;
          margin: 0 auto;
          opacity: 1;

          &:hover {
            transition: all .2s ease;
            opacity: 0.5;
          }
        }
      }
    }
  }

  .recruit-education {
    margin-top: 40px;
    padding-bottom: 60px;

    @include media-up(md) {
      margin-top: 120px;
      padding-bottom: 100px;
    }
  }

  .recruit-education__title {

    h2 {
      text-align: center;
      font-size: 20px;
      font-weight: bold;

      @include media-up(md) {
        font-size: 24px;
      }

      span {
        display: block;
        font-size: 14px;

        @include media-up(md) {
          font-size: 24px;
          margin-top: 10px;
        }
      }
    }

    p {
      margin-top: 20px;
      font-size: 13px;

      @include media-up(md) {
        max-width: 780px;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        font-size: 20px;
        margin-top: 40px;
      }
    }

  }

  .recruit-education-figure {
    margin-top: 40px;
    margin-bottom: 40px;

    @include media-up(md) {
      margin-top: 80px;
      margin-bottom: 80px;
      text-align: center;
    }
  }

  .recruit-education-point {
    margin-top: 40px;

    @include media-up(md) {
      margin-top: 80px;
    }

    dl {
      @include media-up(md) {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &:nth-of-type(even) {
          flex-direction: row-reverse;
        }

      }

      +dl {
        margin-top: 30px;

        @include media-up(md) {
          margin-top: 50px;
        }
      }

      dt {
        @include media-up(md) {
          width: 400px;
        }

        img {
          vertical-align: middle;
        }
      }

      dd {
        margin-top: 10px;

        @include media-up(md) {
          margin-top: 0;
          width: calc(100% - 450px);
        }

        h3 {
          font-weight: bold;
          font-size: 18px;

          @include media-up(md) {
            font-size: 22px;
          }
        }

        p {
          font-size: 13px;

          @include media-up(md) {
            font-size: 16px;
            margin-top: 10px;
          }
        }
      }
    }
  }

  .recruit-gallery {
    margin-top: 10px;

    @include media-up(md) {
      margin-top: 80px;
    }

    img {
      vertical-align: middle;
    }

    * {
      vertical-align: middle;
    }
  }

  .recruit-requirements {
    padding: 40px 0;
    background-color: rgba($color-black,0.1);

    @include media-up(md) {
      padding: 80px 0;
    }
  }

  .recruit-requirements__title {
    // color: $color-white;
    text-align: center;

    h2 {
      font-size: 20px;
      font-weight: bold;

      @include media-up(md) {
        font-size: 24px;
      }

      span {
        display: block;
        font-size: 14px;

        @include media-up(md) {
          font-size: 24px;
          margin-top: 10px;
        }
      }
    }

    p {
      margin-top: 20px;
      font-size: 13px;

      @include media-up(md) {
        max-width: 780px;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        font-size: 20px;
        margin-top: 40px;
      }
    }

  }

  .recruit-requirements-inner {
    background-color: $color-white;
    margin: 30px 0px;
    padding: 30px;
    border: 6px solid #000;

    @include media-up(md) {
      margin: 40px 80px 0;
      padding: 50px;
    }

    h2 {
      font-size: 18px;
      text-align: center;
      font-weight: bold;

      @include media-up(md) {
        font-size: 24px;
      }
    }

    .qualification {
      border: 2px solid #000;
      margin-top: 20px;
      padding: 20px;

      @include media-up(md) {
        margin-top: 40px;
        padding: 20px 40px;
        font-size: 14px;
      }
    }

    .figure {
      margin-top: 30px;

      p {
        margin-top: 15px;
        padding: 0 10px;
        font-size: 13px;

        @include media-up(md) {
          padding: 0 30px;
          font-size: 16px;
        }
      }
    }
  }

  .recruit-treatment {
    margin: 30px auto;
    padding: 30px;

    @include media-up(md) {
      margin: 40px auto;
      padding: 30px;
    }

    .figure {
      padding: 20px 0;

      @include media-up(md) {
        padding: 40px 80px;
      }
    }
  }

  .recruit-treatment__title {
    text-align: center;

    h2 {
      img {
        width: 80%;
        max-width: 400px;
        height: auto;
      }
    }

  }


  .recruit-student {
    background-color: #ef8382;
    padding: 30px;

    @include media-up(md) {
      padding: 80px 0;
    }
  }

  .recruit-student-inner {
    background-color: $color-white;
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;

    @include media-up(md) {
      margin: 0px 80px;
      padding: 50px;
    }
  }

  .recruit-student__title {
    text-align: center;

    h2 {
      font-size: 20px;
      font-weight: bold;

      @include media-up(md) {
        font-size: 24px;
      }
    }

    p {
      margin-top: 20px;
      text-align: center;
      font-size: 13px;

      @include media-up(md) {
        font-size: 18px;
      }
    }

  }

  .recruit-student-contact {

    h2 {
      background-image: url(../img/recruit/bg-ribbon.png);
      background-size: 100% 100%;
      font-size: 20px;
      font-weight: bold;
      color: $color-white;
      max-width: 300px;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 1;
      padding: 20px 40px;
      margin: 40px auto 10px;
    }

    p {
      font-size: 14px;
      text-align: center;

      @include media-up(md) {
        font-size: 16px;
      }

      br {
        @include media-up(md) {
          display: none;
        }
      }
    }

    ul {
      display: flex;
      flex-direction: column;

      @include media-up(md) {
        flex-direction: row;
        max-width: 500px;
        justify-content: center;
        margin: 20px auto;
      }

      li {
        width: 200px;
        margin: 20px auto 0;

        a {
          background-color: #edd53a;
          color: $color-white;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 15px 20px;
          font-size: 16px;
          font-weight: bold;
          width: 100%;
          border-radius: 5px;

          @include media-up(md) {
            padding: 10px 20px;
            font-size: 18px;
            opacity: 1;

            &:hover {
              opacity: 0.5;
              transition: all .2s ease;
            }
          }
        }
      }

    }
  }

  .recruit-contact {
    background-color: #F7F7F7;
    padding-top: 80px;

    @include media-up(md) {
      padding-top: 200px;
    }
  }

  .recruit-contact__header {
    text-align: center;

    h2 {
      font-size: 20px;
      font-weight: bold;

      @include media-up(md) {
        font-size: 24px;
      }
    }

  }

  .recruit-contact-form {
    @include media-up(md){
      padding: 0 10%;
    }
    .form-item {
      display: flex;
      flex-direction: column;
      border-top: 1px solid #A0A0A0;
      padding: 20px 10px;

      @include media-up(md){
        flex-direction: row;
        align-items: center;
      }

      +.form-item {
        // margin-top: 30px;
      }

      dt {
        font-size: 16px;

        @include media-up(md){
          width: 200px;
          font-size: 14px;
          padding-right: 20px;
        }

        span {
          color: red;
        }
      }

      dd {
        font-size: 16px;
        margin-top: 10px;

        @include media-up(md){
          font-size: 14px;
          margin-top: 0;
          width: calc(100% - 200px);
        }

        &.is-select {
          position: relative;
          min-width: 300px;

          &::after {
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            border-top: 8.5px solid black;
            content: "";
            position: absolute;
            width: 0;
            display: block;
            top: 50%;
            right: 20px;
            -webkit-transform: translateY(-50%);
            /* Safari用 */
            transform: translateY(-50%);
          }
        }

        &.is-2col {
          display: flex;
          justify-content: space-between;

          * {
            width: 48%;
          }
        }

        select {
          -webkit-appearance: none;
          appearance: none;
          /* デフォルトの矢印を非表示 */
        }

        select::-ms-expand {
          display: none;
          /* デフォルトの矢印を非表示(IE用) */
        }

        select {
          background-color: #fff;
          padding: 10px 15px;
          width: 100%;
        }

        .input-item {
          display: block;

          input {
            background-color: #fff;
            padding: 10px 15px;
            font-size: 16px;
            width: 100%;
          }

          textarea {
            background-color: #fff;
            padding: 10px 15px;
            font-size: 16px;
            width: 100%;
          }
        }

        p {
          margin-top: 5px;
          font-size: 12px;
        }

        .adr1 {
          input {
            background-color: #fff;
            padding: 10px 15px;
            font-size: 16px;

          }
        }

        .adr2 {
          .input-item {
            margin-top: 15px;

            input {
              margin-top: 5px;
            }

            select {
              margin-top: 5px;
            }

            span {
              &.is-select {
                position: relative;
                min-width: 300px;

                &::after {
                  border-left: 8px solid transparent;
                  border-right: 8px solid transparent;
                  border-top: 8.5px solid black;
                  content: "";
                  position: absolute;
                  width: 0;
                  display: block;
                  top: 50%;
                  right: 20px;
                  -webkit-transform: translateY(-50%);
                  /* Safari用 */
                  transform: translateY(-50%);
                }
              }
            }
          }
        }
      }
    }

    .form-btn {
      padding-top: 60px;
      border-top: 1px solid #A0A0A0;

      input {
        background-color: #ef8382;
        color: $color-white;
        font-weight: bold;
        width: 300px;
        font-size: 18px;
        text-align: center;
        display: block;
        padding: 20px;
        border-radius: 5px;
        opacity: 1;
        &:hover {
          opacity: 0.5;
          transition: all .2s ease;
        }
      }
    }
  }
}

#news {
  .news-header {
    text-align: center;
    padding-top: 40px;

    @include media-up(md){
      padding-top: 0;
    }

    .news-header__title {
      span {
        font-size: 20px;
        font-weight: bold;
        font-family: $font-en;

        @include media-up(md){
          font-size: 30px;
        }
      }
    }

  }

  .news-contents {
    margin-top: 30px;
    padding-bottom: 40px;

    @include media-up(md){
      margin-top: 40px;
      padding-bottom: 40px;
      padding-left: 50px;
      padding-right: 50px;
    }


    .news-list {
      @include media-up(md){
        padding-left: 10%;
        padding-right: 10%;
      }
      .entry {
        background-color: $color-white;
        transition: all .2s ease;
        opacity: 1;

        &:hover {
          opacity: 0.5;
        }


        + .entry {
          margin-top: 15px;
        }

        a {
          text-decoration: none;
          padding: 20px;
          display: block;
          color: $color-black;

          dt {
            line-height: 1;
          }

          h2 {
            font-size: 14px;
            margin-top: 10px;

            @include media-up(md){
              font-size: 16px;
            }
          }
        }
      }
    }

    .news-contents__base {
      background-color: $color-white;
      margin-top: 5px;
      padding: 20px;

      @include media-up(md){
        padding: 80px;
      }
    }

    .news-title {
      border-bottom: 2px solid #000;
      padding-bottom: 10px;
      h1 {
        text-align: left;
        font-size: 20px;

        @include media-up(md){
          font-size: 34px;
        }
      }
    }

    .news-desc {
      @include media-up(md){
        font-size: 16px;
        margin-top: 10px;
      }
    }

    .news-entry {
      margin-top: 15px;

      @include media-up(md){
        margin-top: 40px;
        font-size: 16px;
      }

      p {
        line-height: 1.8;
        margin-bottom: 10px;
      }

      h2 {
        text-align: left;
        font-size: 18px;

        @include media-up(md){
          font-size: 28px;
        }
      }

      h3 {
        text-align: left;
        font-size: 16px;

        @include media-up(md){
          font-size: 24px;
        }
      }

      .wp-block-image {
        margin: 20px 0;

        img {
          max-width: 100%;
          height: auto;
        }
      }
    }

  }

  .p-pagination {
    display: flex;
    justify-content: center;
    flex-direction: row;
    padding-bottom: 40px;

    .page-numbers {
      display: flex;
      justify-content: center;

      li {
        margin: 0 6px;
        border: 1px solid #000;
        display: flex;
        align-items: center;
        justify-content: center;

        span, a {
          width: 35px;
          height: 35px;
          display: flex;
          align-items: center;
          justify-content: center;
          line-height: 1;
        }

        a {
          text-decoration: none;
          color: $color-black;
        }

        span {
          background-color: $color-black;
          color: $color-white;
        }
      }
    }
  }

  .news-nav {
    margin-top: 30px;
    text-align: center;

    @include media-up(md){
      margin-top: 50px;
      padding-bottom: 30px;
    }

    a {
      color: $color-black;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

#sitemap {
  .sitemap-header {
    background-image: url(../img/sitemap/header_sp.jpg);
    background-size: cover;
    background-position: center center;

    @include media-up(md){
      background-image: url(../img/sitemap/header_pc.jpg);
    }
  }

  a {
    color: $color-black;
    &:hover {
      text-decoration: none;
    }
  }
  .sitemap-contents {
    margin-top: 30px;
    padding-bottom: 40px;

    @include media-up(md){
      margin-top: 50px;
      padding-bottom: 80px;
      padding-left: 50px;
      padding-right: 50px;
    }
  }

  .sitemap-content-info {

    @include media-up(md){
      display: flex;
      flex-wrap: wrap;
      margin-left: -20px;
    }


    + .sitemap-content-info {
      margin-top: 40px;

      @include media-up(md){
        margin-top: 0;
      }
    }

    @include media-up(md){
    }

    .sitemap-content-info__item {
      padding-bottom: 20px;
      background-color: $color-white;
      margin-bottom: 20px;

      @include media-up(md){
        width: calc(50% - 20px);
        margin-left: 20px;
      }

      + .sitemap-content-info__item{
      }

      h2 {
        margin-top: 20px;
        font-size: 14px;
        padding: 10px 20px;

        border-bottom: 1px solid #eee;

        @include media-up(md){
          font-size: 20px;
          margin-top: 0;
        }
      }

      p {
        margin-top: 10px;
        font-size: 12px;


        @include media-up(md){
          font-size: 14px;
        }
      }

      ul {
        padding: 20px;
        font-size: 12px;
        @include media-up(md){
          font-size: 14px;
        }

        li {

          + li {
            margin-top: 10px;
          }
          &::before {
            content :'-';
            margin-right: 10px;
          }
        }
      }
    }


  }
}

#profile {
  .profile-header {
    background-image: url(../img/profile/header_sp.jpg);
    background-size: cover;
    background-position: center center;

    @include media-up(md){
      background-image: url(../img/profile/header_pc.jpg);
    }
  }

  .profile-contents {
    margin-top: 30px;
    padding-bottom: 40px;

    @include media-up(md){
      margin-top: 50px;
      padding-bottom: 80px;
      padding-left: 50px;
      padding-right: 50px;
    }
  }

  .profile-content-info {
    background-color: $color-white;
    padding: 20px;

    + .profile-content-info {
      margin-top: 40px;
    }

    @include media-up(md){
      padding: 40px 80px;
    }

    .profile-content-info__title {
      padding-bottom: 20px;
      text-align: center;
      font-size: 18px;
      font-weight: bold;

      @include media-up(md){
        padding-bottom: 40px;
        font-size: 22px;
      }
    }

    .profile-content-info__list {
      border-top: 1px dotted #000;

      dl {
        padding: 10px;
        border-bottom: 1px dotted #000;

        @include media-up(md){
          display: flex;
          align-items: center;
          padding: 20px;
        }

        dt {
          font-size: 12px;
          font-weight: bold;

          @include media-up(md){
            width: 220px;
            font-size: 13px;
          }
        }

        dd {
          font-size: 14px;
          margin-top: 5px;

          @include media-up(md){
            width: calc(100% - 250px);
            font-size: 16px;
            margin-top: 0;
          }
        }
      }
    }
  }
}

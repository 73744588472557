#price {
  .price-header {
    text-align: center;
    padding-top: 40px;

    @include media-up(md){
      padding-top: 0;
    }

    .price-header__title {
      h1 {
        font-size: 20px;
        font-weight: bold;
        font-family: $font-en;

        @include media-up(md){
          font-size: 30px;
        }
      }
    }

  }

  .price-contents {
    margin-top: 30px;
    padding-bottom: 40px;

    @include media-up(md){
      margin-top: 40px;
      padding-bottom: 80px;
      padding-left: 50px;
      padding-right: 50px;
    }


    .price-contents__caption {
      text-align: right;
      font-size: 10px;

      @include media-up(md){
        font-size: 12px;
      }
    }

    .price-contents__list {
      background-color: $color-white;
      margin-top: 5px;

      @include media-up(md){
        padding: 50px;
      }

      dl {
        border-bottom: 1px dotted #B4B4B4;
        padding: 10px 14px;
        display: flex;
        font-size: 12px;

        @include media-up(md){
          padding: 20px;
          font-size: 16px;
        }

        &:first-child {
          @include media-up(md){
            border-top: 1px dotted #B4B4B4;
          }
        }

        &:last-child {
          border-bottom: 0px dotted #B4B4B4;

          @include media-up(md){
            border-bottom: 1px dotted #B4B4B4;
          }
        }

        dt {
          width: 45%;
        }
        dd {
          width: 55%;
        }
      }

    }

    .price-contents__text {
      margin-top: 5px;
      font-size: 10px;

      @include media-up(md){
        margin-top: 20px;
        padding: 0 10px;
        font-size: 12px;
      }
    }

  }
}

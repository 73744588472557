#eyelash {
  background: $color-white;

  .eyelash-header {
    background-image: url(../img/eyelash/header_sp.jpg);
    background-size: cover;
    background-position: center center;

    @include media-up(md){
      background-image: url(../img/eyelash/header_pc.jpg);
    }
  }

  .eyelash-copy {
    margin-top: 45px;

    @include media-up(md){
      margin-top: 90px;
    }

    h2 {
      font-size: 18px;
      font-weight: bold;

      @include media-up(md){
        text-align: center;
        font-size: 24px;
      }

      br {
        display: none;
        @include media-up(md){
          display: block;
        }
      }
    }

    p {
      font-size: 14px;
      margin-top: 35px;

      @include media-up(md){
        font-size: 16px;
        margin-top: 75px;
        line-height: 2;
        text-align: center;
      }

      br {
        display: none;

        @include media-up(md){
          display: block;
        }
      }

    }
  }

  .eyelash-contents {
    margin-top: 40px;
    padding-bottom: 60px;

    @include media-up(md){
      margin-top: 60px;
      padding-bottom: 110px;
    }
  }

  .eyelash-contents__item {
    dl {
      display: flex;
      flex-direction: column;

      @include media-up(md){
        flex-direction: row;
        justify-content: space-between;
      }

      dt {
        @include media-up(md){
          width: 340px;
        }
        img {
          width: 100%;
          height: auto;
          vertical-align: middle;
        }
      }

      dd {
        margin-top: 20px;

        @include media-up(md){
          margin-top: 20px;
          width: calc(100% - 380px);

        }

        h3{
          font-size: 15px;
          font-weight: bold;

          @include media-up(md){
            font-size: 20px;
          }

          br {
            display: none;

            @include media-up(md){
              display: inherit;
            }
          }
        }

        p {
          margin-top: 10px;
          font-size: 12px;

          @include media-up(md){
            font-size: 14px;
            line-height: 2;
          }
        }
      }
    }
    + .eyelash-contents__item {
      margin-top: 40px;

      @include media-up(md){

        dl {
          flex-direction: row-reverse;
        }
      }
    }
  }

  .eyelash-price {
    background-color: $color-gray;
    padding-top: 40px;
    padding-bottom: 0px;

    @include media-up(md){
      padding-top: 80px;
      padding-bottom: 0px;
    }
  }

  .eyelash-price__title {
    text-align: center;
    font-size: 20px;
    font-family: $font-en;
    font-weight: bold;

    @include media-up(md){
      font-size: 24px;
    }
  }

  .eyelash-price-contents {
    margin-top: 30px;
    padding-bottom: 40px;

    @include media-up(md){
      margin-top: 40px;
      padding-bottom: 80px;
      padding-left: 50px;
      padding-right: 50px;
    }


    .eyelash-price-contents__caption {
      text-align: right;
      font-size: 10px;

      @include media-up(md){
        font-size: 12px;
      }
    }

    .eyelash-price-contents__list {
      background-color: $color-white;
      margin-top: 5px;

      @include media-up(md){
        padding: 50px;
      }

      dl {
        border-bottom: 1px dotted #B4B4B4;
        padding: 10px 14px;
        display: flex;
        font-size: 12px;

        @include media-up(md){
          padding: 20px;
          font-size: 16px;
        }

        &:first-child {
          @include media-up(md){
            border-top: 1px dotted #B4B4B4;
          }
        }

        &:last-child {
          border-bottom: 0px dotted #B4B4B4;

          @include media-up(md){
            border-bottom: 1px dotted #B4B4B4;
          }
        }

        dt {
          width: 65%;

          span {
            display: block;
            font-size: 10px;
            margin-top: 5px;
            padding-right: 20px;
          }
        }
        dd {
          width: 35%;
        }
      }

    }

    .eyelash-price-contents__supplement {
      font-size: 10px;
      margin-top: 10px;

      @include media-up(md){
        text-align: right;
        font-size: 12px;
      }
    }

    .eyelash-price-contents__text {
      margin-top: 5px;
      font-size: 10px;

      @include media-up(md){
        margin-top: 20px;
        padding: 0 10px;
        font-size: 12px;
      }
    }

  }

  .salon-list {
    padding-top: 30px;
    margin-bottom: 50px;

    @include media-up(md){
      padding-top: 60px;
      margin-bottom: 100px;
    }
    .salon-list__title {
      h2 {
        font-size: 16px;
        font-weight: bold;
        text-align: center;

        @include media-up(md){
          font-size: 30px;
        }
      }
    }

    .salon-list__list {
      display: flex;
      flex-wrap: wrap;
      margin-left: -10px;
      margin-top: 10px;

      @include media-up(md){
        margin-left: -30px;
        margin-top: 10px;
      }

      li {
        width: calc(50% - 10px);
        margin-left: 10px;
        margin-top: 20px;

        @include media-up(md){
          width: calc(33.333% - 30px);
          margin-left: 30px;
          margin-top: 50px;
        }

        dl {
          dt{
            a {
              display: block;
            }
            img {
              width: 100%;
              height: auto;
              vertical-align: middle;
            }
          }
          dd {
            text-align: center;
            margin-top: 10px;

            .name {
              font-size: 16px;
              font-weight: bold;
              font-family: $font-en;

              @include media-up(md){
                font-size: 20px;
              }
            }
            .adr {
              font-size: 10px;

              @include media-up(md){
                font-size: 12px;
              }
            }
            .tel {
              font-size: 14px;
              font-weight: bold;

              @include media-up(md){
                font-size: 20px;
                line-height: 1;
                margin-top: 5px;
              }
              span {
                font-size: 11px;

                @include media-up(md){
                  font-size: 14px;
                }
              }
            }
            .link {
              margin-top: 5px;
              display: flex;
              justify-content: center;

              @include media-up(md){
                margin-top: 15px;
              }

              a {
                margin: 0 3px;
                font-size: 10px;
                padding: 10px 7px;

                @include media-up(xxs){
                  padding: 5px 10px;
                  font-size: 12px;
                }


                @include media-up(md){
                  font-size: 18px;
                  margin: 0 6px;
                  padding: 10px 20px;
                }
              }
            }

          }
        }
      }
    }
  }

  .staff-contents {
    margin-top: 30px;
    padding-bottom: 40px;

    @include media-up(md){
      margin-top: 60px;
      padding-bottom: 80px;
    }

    .staff-contents__list {

    }
    .staff-contents__section {
      + .staff-contents__section {
        margin-top: 40px;

        @include media-up(md){
          margin-top: 80px;
        }
      }

      h2 {
        font-size: 16px;
        font-family: $font-en;
        font-weight: bold;

        @include media-up(md){
          font-size: 20px;
        }
      }
      ul {
        margin-top: 15px;
        display: flex;
        flex-wrap: wrap;
        margin-left: -10px;
        margin-top: -5px;

        @include media-up(md){
          margin-left: -20px;
          margin-top: -10px;
        }

        li {
          width: calc(50% - 10px);
          margin-left: 10px;
          position: relative;
          margin-top: 10px;
          border: 1px solid rgb(232, 232, 232);

          @include media-up(md){
            margin-left: 20px;
          }

          @include media-up(xs){
            width: calc(33.333% - 10px);
          }

          @include media-up(md){
            width: calc(25% - 20px);
            margin-left: 20px;
            margin-top: 20px;
          }

          @include media-up(lg){
            width: calc(20% - 20px);
          }

          .salon {
            position: absolute;
            background-color: #C7C7C7;
            padding: 5px 10px;
            right: 0;
            top:0;
            line-height: 1;
            vertical-align: middle;
            font-size: 10px;
            color: $color-white;
            font-family: $font-en;
            min-width: 50px;
            text-align: center;

            @include media-up(md){
              font-size: 12px;
            }
          }


          dl {
            background-color: $color-white;
            dt {
              img {
                width: 100%;
                height: auto;
                vertical-align: middle;
                image-rendering: pixelated;
              }
            }

            dd {
              padding: 5px;
              display: flex;

              @include media-up(md){
                padding: 15px;
              }

              .name {
                width: calc(100% - 35px);

                p {
                  font-size: 14px;
                  letter-spacing: 0.1em;

                  @include media-up(md){
                    font-size: 17px;
                  }

                  span {
                    display: block;
                    font-size: 10px;
                    font-weight: normal;
                    letter-spacing: 0.04em;

                    @include media-up(md){
                      font-size: 12px;
                    }
                  }
                }

                .title {
                  font-size: 8px;

                  @include media-up(md){
                    font-size: 10px;
                  }
                }
              }

              .sns {
                width: 35px;

                a {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  height: 100%;
                  padding: 5px;

                  @include media-up(md){
                    opacity: 1;
                    transition: all .1s ease;

                    &:hover {
                      opacity: 0.5;
                    }
                  }

                  img {
                    width: 100%;
                    height: auto;
                    vertical-align: middle;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

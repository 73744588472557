#wedding {
  background: $color-white;

  .wedding-header {
    background-image: url(../img/wedding/header_sp.jpg);
    background-size: cover;
    background-position: center center;

    @include media-up(md){
      background-image: url(../img/wedding/header_pc.jpg);
    }
  }

  .wedding-copy {
    margin-top: 45px;

    @include media-up(md){
      margin-top: 90px;
    }

    h2 {
      font-size: 18px;
      font-weight: bold;
      text-align: center;

      @include media-up(md){
        font-size: 24px;
      }

      br {
        @include media-up(md){
          display: none;
        }
      }
    }

    p {
      font-size: 14px;
      margin-top: 35px;

      @include media-up(md){
        font-size: 16px;
        margin-top: 75px;
        line-height: 2;
        text-align: center;
      }

      br {
        display: none;

        @include media-up(md){
          display: inherit;
        }
      }
    }
  }

  .wedding-contents {
    margin-top: 40px;
    padding-bottom: 60px;

    @include media-up(md){
      margin-top: 60px;
      padding-bottom: 100px;
    }
  }

  .wedding-contents__item {
    dl {
      display: flex;
      flex-direction: column;

      @include media-up(md){
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }

      dt {
        @include media-up(md){
          width: 340px;
        }
        img {
          width: 100%;
          height: auto;
          vertical-align: middle;
        }
      }

      dd {
        margin-top: 20px;

        @include media-up(md){
          width: calc(100% - 380px);
          margin-top: 0;
        }

        ul {
          display: flex;
          flex-direction: column;

          li {
            display: flex;

            span {
              width: 25px;
              display: block;
            }

            p {
              width: calc(100% - 25px);
              padding-left: 10px;
              font-size: 14px;

              @include media-up(md){
                font-size: 16px;
              }

              br {
                display: none;

                @include media-up(md){
                  display: inherit;
                }
              }
            }

            + li {
              margin-top: 10px;

              @include media-up(md){
                margin-top: 15px;
              }
            }
          }
        }
      }
    }
  }

  .wedding-price {
    background-color: $color-gray;
    padding-top: 40px;
    padding-bottom: 0px;

    @include media-up(md){
      padding-top: 80px;
      padding-bottom: 0px;
    }
  }

  .wedding-price__title {
    text-align: center;
    font-size: 20px;
    font-family: $font-en;
    font-weight: bold;

    @include media-up(md){
      font-size: 24px;
    }
  }

  .wedding-price-contents {
    margin-top: 30px;
    padding-bottom: 40px;

    @include media-up(md){
      margin-top: 40px;
      padding-bottom: 80px;
      padding-left: 50px;
      padding-right: 50px;
    }


    .wedding-price-contents__caption {
      text-align: right;
      font-size: 10px;

      @include media-up(md){
        font-size: 12px;
      }
    }

    .wedding-price-contents__list {
      background-color: $color-white;
      margin-top: 5px;

      @include media-up(md){
        padding: 50px;
      }

      dl {
        border-bottom: 1px dotted #B4B4B4;
        padding: 10px 14px;
        display: flex;
        font-size: 10px;

        @include media-up(md){
          padding: 20px;
          font-size: 16px;
        }

        &:first-child {
          @include media-up(md){
            border-top: 1px dotted #B4B4B4;
          }
        }

        &:last-child {
          border-bottom: 0px dotted #B4B4B4;

          @include media-up(md){
            border-bottom: 1px dotted #B4B4B4;
          }
        }

        dt {
          width: 45%;
        }
        dd {
          width: 55%;

          > br {
            @include media-up(md){
              display: none;
            }
          }

          span {
            font-size: 10px;
            margin-top: 5px;
            display: block;

            @include media-up(md){
              font-size: 12px;
            }
          }
        }
      }

    }

    .wedding-price-contents__text {
      margin-top: 5px;
      font-size: 10px;

      @include media-up(md){
        margin-top: 20px;
        padding: 0 10px;
        font-size: 12px;
      }
    }

  }
}

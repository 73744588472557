.bread {
  border-top: 1px solid $color-gray;
  border-bottom: 1px solid $color-gray;
  padding: 10px 0;

  ul {
    display: flex;

    li {
      font-size: 10px;

      + li {
        margin-left: 10px;
      }

      a {
        text-decoration: none;
        color: $color-black;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.global-footer {
  border-top: 1px solid $color-gray;
  .global-footer__inner {
    padding-top: 45px;

    @include media-up(md){
      padding-top: 60px;
    }
  }

  .global-footer-nav {
    padding: 0 2px;
    ul {
      display: flex;
      flex-wrap:wrap;
      justify-content: center;

      + ul {
        margin-top: 10px;
      }

      li {
        a {
          color: $color-black;
          text-decoration: none;
          font-size: 10px;

          @include media-up(md){
            font-size: 12px;

            &:hover {
              text-decoration: underline;
            }
          }
        }

        + li {
          &:before {
            content:'｜';
            padding: 0 2px;
          }
        }
      }
    }
  }

  .global-footer-copyright {
    text-align: center;
    padding: 30px 10px;
    font-size: 10px;
    font-family: $font-en;

    @include media-up(md){
      padding: 50px 0;
      font-size: 14px;
    }
  }
}


.fixed-nav {
  background: rgba($color-white,0.95);
  position: fixed;
  bottom: 0;
  width: 65px;
  height: 65px;
  right:0;
  display: none;
  transition:all .1s ease-out;

  @include media-up(md){
    display: none;
  }

  &.is-open {
    width: 100%;
    background:rgba($color-black,0.8);

    .fixed-nav__button div {
      margin-top: -2px;
      span {
        background-color: $color-white;
      }
      > span:nth-child(1) {
        -webkit-transform: translateY(8px) rotate(-45deg);
        transform: translateY(8px) rotate(-45deg);
      }

      > span:nth-child(2) {
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
      }

      > span:nth-child(3) {
        -webkit-transform: translateY(-8px) rotate(45deg);
        transform: translateY(-8px) rotate(45deg);
      }
    }

    .fixed-nav__menu {
      animation: show 0.5s linear 0s;
      visibility: visible;
      display: block;
    }
  }

  .fixed-nav__button {
    width: 65px;
    height: 65px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right:0;
    bottom: 0;

    div {
      position: relative;
      width: 25px;
      height: 70px;
      margin-right: auto;
      margin-left: auto;

      > span {
        position: absolute;
        right: 0;
        width: 100%;
        height: 3px;
        background-color: $color-black;
        -webkit-transition: .2s ease-out;
        transition: .2s ease-out;
      }

      > span:nth-child(1) {
        top: 28px;
      }

      > span:nth-child(2) {
        top: 36px;
      }

      > span:nth-child(3) {
        top: 44px;
      }
    }
  }

  .fixed-nav__menu {
    display: none;
    visibility: hidden;
    width: calc(100% - 65px);
    ul {
      display: flex;
      li {
        width: 25%;
        a {
          height: 65px;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            height: 40px;
            width: auto;
          }
        }
      }
    }
  }
}


@keyframes show{
  from{
      opacity: 0;
  }
  to{
      opacity: 1;
  }
}
